/**
 * This component generally needs to be rewritten to use tanstack table.
 */
import * as Sentry from "@sentry/react";
import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { CdkDealJacketStatus } from "@thedealersconcierge/lib/codecs/tdc";
import classNames from "classnames";
import { format } from "date-fns";
import { useAtomValue } from "jotai";
import { PDFDocument } from "pdf-lib";
import { Fragment, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import pushDocumentToCdkAction from "~/actions/documents/pushDocumentToCdkAction";
import pushFormSubmissionToCdkAction from "~/actions/formSubmissions/pushFormSubmissionToCdkAction";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Button from "~/components/Button";
import Spinner from "~/components/Spinner";
import {
  MultiStateCheckbox,
  MultiStateCheckboxState,
} from "~/components/design-system-components/MultiStateCheckbox";
import { getReadableFormSubmissionType } from "~/lib/enumReadable";
import { queryClient } from "~/lib/query";
import { Link, useNavigate, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import ActionBar from "./_components/actionBar";
import DealJacketPageNav from "./_components/pageNav";
import UploadDocumentModal from "./_components/uploadDocumentModal";
import {
  DocumentLine,
  generatePageCompositions,
  getHumanReadableCdkDealJacketStatus,
  handlePrintPrompt,
  PageComposition,
  PaginationStateWrapper,
} from "./_dealJacketUtils";
import { dealJacketQuery } from "./_queries/dealJacketQuery";

const ITEMS_PER_PAGE = 50;

export default function DealJacketPage() {
  const navigate = useNavigate();
  const { transactionId } = useParams(
    "/dashboard/transaction/:transactionId/dealJacket"
  );
  const [pageCompositions, setPageCompositions] = useState<PageComposition[]>(
    []
  );

  // We start the page on 1 to avoid page and items calculation
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState<PaginationStateWrapper>({
    fs: {
      currentPage: 1,
      pageSize: 0,
      direction: "after",
      cursor: undefined,
    },
    doc: {
      currentPage: 1,
      pageSize: 0,
      direction: "after",
      cursor: undefined,
    },
  });
  const [selectedMask, setSelectedMask] = useState<{
    [selectedId: string]: boolean;
  }>({});
  const [isPushingToDms, setIsPushingToDms] = useState(false);
  const dealership = useAtomValue(dealershipAtom);
  const { data, isLoading, refetch } = useQuery(
    dealJacketQuery(transactionId, pagination.fs, pagination.doc)
  );
  const transaction = data?.transaction;
  let fsCount = transaction?.formSubmissions.totalCount ?? 0;
  let docCount = transaction?.documents.totalCount ?? 0;
  const totalItem = fsCount + docCount;
  const totalPages = Math.ceil(totalItem / ITEMS_PER_PAGE);

  // Initialize the remaining counts based on the transaction data
  useEffect(() => {
    if (transaction && currentPage === 1) {
      const initialFsPageSize = Math.min(fsCount, ITEMS_PER_PAGE);
      const initialDocPageSize = Math.min(
        docCount,
        ITEMS_PER_PAGE - initialFsPageSize
      );

      setPagination((prev) => ({
        fs: {
          ...prev.fs,
          pageSize: initialFsPageSize,
        },
        doc: {
          ...prev.doc,
          pageSize: initialDocPageSize,
        },
      }));
    }

    if (pageCompositions.length <= 0) {
      setPageCompositions(
        generatePageCompositions(fsCount, docCount, ITEMS_PER_PAGE)
      );
    }
  }, [transaction]);

  const items: DocumentLine[] = useMemo(() => {
    const formSubmissions = (transaction?.formSubmissions.edges ?? [])
      .map((edge) => ({ ...edge.node, cursor: edge.cursor }))
      .map((currentSubmission) => {
        const cSubmissoin: DocumentLine = {
          id: currentSubmission.id,
          userId: currentSubmission.userId,
          displayTitle:
            currentSubmission.form?.displayTitle ??
            getReadableFormSubmissionType(currentSubmission.type) ??
            "No title",
          type: currentSubmission.type,
          createdAt: new Date(currentSubmission.createdAt),
          access: currentSubmission.access,
          name: `${currentSubmission.customer.firstName} ${currentSubmission.customer.lastName}`,
          isDocument: false,
          file: currentSubmission.file,
          cdkDealJacketStatus: currentSubmission.cdkDealJacketStatus,
        };
        return cSubmissoin;
      });

    const documents: DocumentLine[] = (transaction?.documents.edges ?? []).map(
      (d) => ({
        ...d.node,
        cursor: d.cursor,
        id: d.node.id,
        userId: d.node.userId,
        displayTitle: d.node.title,
        type: d.node.category,
        createdAt: new Date(d.node.createdAt),
        access: d.node.access,
        name: `${d.node.customer.firstName ?? ""} ${d.node.customer.lastName ?? ""}`.trim(),
        isDocument: true,
        file: d.node.file,
        cdkDealJacketStatus: d.node.cdkDealJacketStatus,
      })
    );

    return [...formSubmissions, ...documents];
  }, [transaction]);

  const handleNextPage = () => {
    if (currentPage < pageCompositions.length) {
      const nextPage = currentPage + 1;
      const { fs, doc } = pageCompositions[nextPage - 1];

      setPagination((prev) => ({
        ...prev,
        fs: {
          ...prev.fs,
          currentPage: fs.currentPage,
          pageSize: fs.pageSize,
          cursor:
            transaction?.formSubmissions?.edges[
              transaction?.formSubmissions.edges.length - 1
            ]?.cursor,
          direction: "after",
        },
        doc: {
          ...prev.doc,
          currentPage: doc.currentPage,
          pageSize: doc.pageSize,
          cursor:
            transaction?.documents?.edges[
              transaction?.documents.edges.length - 1
            ]?.cursor,
          direction: "after",
        },
      }));

      setCurrentPage(nextPage);
      refetch();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      const { fs, doc } = pageCompositions[prevPage - 1];

      setPagination((prev) => ({
        ...prev,
        fs: {
          ...prev.fs,
          currentPage: fs.currentPage,
          pageSize: fs.pageSize,
          cursor: transaction?.formSubmissions?.edges[0]?.cursor,
          direction: "before",
        },
        doc: {
          ...prev.doc,
          currentPage: doc.currentPage,
          pageSize: doc.pageSize,
          cursor: transaction?.documents?.edges[0]?.cursor,
          direction: "before",
        },
      }));

      setCurrentPage(prevPage);
      refetch();
    }
  };

  const enablePrintAndSelect = dealership?.activeDealershipPerms
    ? permissionChecker("printDealerJacket", dealership.activeDealershipPerms)
    : false;

  const getSelectedIds = () => {
    const selectedIds = Object.entries(selectedMask)
      .filter(([, selected]) => selected)
      .map(([id]) => id);
    return selectedIds;
  };

  const handleOpenMultipleDocument = (documentIds: string[]) => {
    navigate("/dashboard/transaction/:transactionId/dealJacket/view", {
      params: {
        transactionId,
      },
      state: {
        documentIds,
      },
    });
  };

  const handleDownload = async () => {
    const selectedIds = getSelectedIds();

    // Populate list of URLs from form submissions and additional documents
    const documentUrls: { id: string; url: string; title: string }[] = [];
    items.forEach((item) => {
      if (!selectedIds.includes(item.id) || !item.file?.url) return;
      documentUrls.push({
        id: item.id,
        url: item.file?.url,
        title: item.isDocument ? item.displayTitle : item.type,
      });
    });

    const pdfDoc = await PDFDocument.create(); // create a new pdf document for bundling

    // Try catch block to handle errors in fetching and adding pages to the pdf
    // The fetch could fail and result in invalid pdfBuffer
    try {
      for (const { url } of documentUrls) {
        const pdfBuffer = await fetch(url).then((res) => res.arrayBuffer());
        const srcPdfDoc = await PDFDocument.load(pdfBuffer);
        const srcPageIndices = Array.from(
          { length: srcPdfDoc.getPageCount() },
          (_, i) => i
        );
        const copiedPages = await pdfDoc.copyPages(srcPdfDoc, srcPageIndices);
        copiedPages.forEach((page) => pdfDoc.addPage(page));
      }

      const combinedPdfBuffer = await pdfDoc.save();

      // Create a blob and trigger the download
      const blob = new Blob([combinedPdfBuffer], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "combined_documents.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      Sentry.captureException(e);
      toast.error(
        "We could not download the documents. Please try again or contact support."
      );
    }
  };

  const handlePrint = async () => {
    const selectedIds = getSelectedIds();

    // Populate list of urls from form submissions and additional documents
    const documentUrls: string[] = [];
    items.forEach((item) => {
      if (!selectedIds.includes(item.id) || !item.file?.url) return;
      documentUrls.push(item.file.url);
    });

    const pdfDoc = await PDFDocument.create(); // create a new pdf document for bundling

    // Try catch block to handle errors in fetching and adding pages to the pdf
    // The fetch could fail and results in invalid pdfBuffer
    try {
      for (const url of documentUrls) {
        const pdfBuffer = await fetch(url).then((res) => res.arrayBuffer());
        const srcPdfDoc = await PDFDocument.load(pdfBuffer);
        const srcPageIndices = Array.from(
          { length: srcPdfDoc.getPageCount() },
          (_, i) => i
        );
        const copiedPages = await pdfDoc.copyPages(srcPdfDoc, srcPageIndices);
        copiedPages.forEach((page) => pdfDoc.addPage(page));
      }
    } catch (e) {
      Sentry.captureException(e);
      toast.error(
        "We could not print the document. Please try again or contact support."
      );
      return;
    }

    const combinedPdfBuffer = await pdfDoc.save();

    // Will open a print prompt on the same page
    // Create a blob and url for the pdf
    const blob = new Blob([combinedPdfBuffer], { type: "application/pdf" });
    handlePrintPrompt(blob);
  };

  const handlePushToDms = async () => {
    try {
      setIsPushingToDms(true);

      const selectedIds = getSelectedIds();
      const notPushed = items
        .map((item) => ({
          id: item.id,
          fileId: item.file?.id,
          documentName: item.displayTitle,
          isDocument: item.isDocument,
          cdkDealJacketStatus: item.cdkDealJacketStatus,
        }))
        .filter(
          (formSubmission) =>
            selectedIds.includes(formSubmission.id) &&
            formSubmission.cdkDealJacketStatus !== "STAGED_FOR_PUSHING" // We don't push documents again when they're in progress
        );
      const notPushedFormSubmissions = notPushed.filter((np) => !np.isDocument);
      const notPushedDocuments = notPushed.filter((np) => np.isDocument);

      await Promise.all(
        notPushedFormSubmissions.map(async (f) => {
          const resp = await pushFormSubmissionToCdkAction(f.id);

          if (resp?.pushFormSubmissionToCdk.__typename === "GraphQLError") {
            throw new Error(resp.pushFormSubmissionToCdk.message);
          }
        })
      );
      await Promise.all(
        notPushedDocuments.map(async (d) => {
          const resp = await pushDocumentToCdkAction(d.id);

          if (resp?.pushDocumentToCdk.__typename === "GraphQLError") {
            throw new Error(resp.pushDocumentToCdk.message);
          }
        })
      );

      await queryClient.resetQueries({
        queryKey: ["transaction-dealjacket", transactionId],
      });

      toast.success("Push to DMS");
    } catch (error: unknown) {
      captureException(error);
      toast.error(
        "Failed to push to DMS. Please contact support to make sure the dealership is setup correctly."
      );
    } finally {
      setIsPushingToDms(false);
    }
  };

  const toggleAll = () => {
    if (items.length > numChecked) {
      const mask = items.reduce(
        (p, c) => ({
          ...p,
          [c.id]: true,
        }),
        {}
      );

      setSelectedMask(mask);
    } else {
      setSelectedMask({});
    }
  };

  const numChecked = Object.values(selectedMask).filter((mv) => !!mv).length;
  let checkboxState;
  if (numChecked === 0) {
    checkboxState = MultiStateCheckboxState.UNSELECTED;
  } else if (numChecked === items.length) {
    checkboxState = MultiStateCheckboxState.SELECTED;
  } else {
    checkboxState = MultiStateCheckboxState.UNSPECIFIED;
  }

  let rowCounter = 0;
  return (
    <>
      {isLoading && (
        <div className="flex relative flex-grow justify-center items-center">
          <Spinner />
        </div>
      )}

      {!isLoading && (
        <div className="flex flex-col space-y-4 w-full pb-10">
          <div className="flex flex-row justify-between">
            <BreadCrumbsContainer>
              <BreadCrumb title="Transaction">
                <Link to={"/dashboard"}>Transactions</Link>
              </BreadCrumb>

              <BreadCrumb title="User">
                <Link
                  to={"/dashboard/transaction/:transactionId"}
                  params={{
                    transactionId,
                  }}
                >
                  {transaction?.title}
                </Link>
              </BreadCrumb>

              <BreadCrumb title="Deal Jacket" />
            </BreadCrumbsContainer>
          </div>

          <div className="flex flex-col">
            {getSelectedIds().length <= 0 && (
              <div className="flex flex-row items-end w-full justify-end">
                <UploadDocumentModal
                  transaction={transaction}
                  refetchTransaction={refetch}
                >
                  {({ openModal }) => (
                    <Button onClick={openModal} variant="PRIMARY">
                      Upload Document
                    </Button>
                  )}
                </UploadDocumentModal>
              </div>
            )}

            {getSelectedIds().length > 0 && (
              <ActionBar
                selectedCount={getSelectedIds().length}
                onSelectAll={toggleAll}
                onView={() => {
                  handleOpenMultipleDocument(getSelectedIds());
                }}
                onDownload={handleDownload}
                onPrint={handlePrint}
                onPushToDMS={handlePushToDms}
                onCloseActionBar={() => {
                  setSelectedMask({});
                }}
                showPushToDms={
                  data?.transaction.dealership?.hasEnabledCdkDms &&
                  [
                    // Maybe it makes sense to share these rules in the TDC package?
                    "ADMIN",
                    "FNI_MANAGER",
                    "SALES_MANAGER",
                  ].includes(dealership?.activeDealershipPerms.role ?? "")
                }
                isPushingToDms={isPushingToDms}
              />
            )}
          </div>

          <div className="w-full rounded-md overflow-hidden shadow-md">
            {/* Look into using: React table, https://tanstack.com/table/v8/docs/api/features/grouping */}
            <table className="w-full overflow-y-scroll text-sm">
              <thead className="rounded-md border-b-2">
                <tr className="bg-white text-black font-bold">
                  <td
                    className={`font-bold px-4 py-5 cursor-pointer`}
                    width={30}
                    onClick={enablePrintAndSelect ? toggleAll : undefined}
                  >
                    <MultiStateCheckbox
                      value={checkboxState}
                      mode="MULTI"
                      onChange={(newValue) => {
                        if (newValue === MultiStateCheckboxState.SELECTED) {
                          // Check all
                          const mask = items.reduce(
                            (p, c) => ({
                              ...p,
                              [c.id]: true,
                            }),
                            {}
                          );
                          setSelectedMask(mask);
                        } else {
                          // Uncheck all
                          setSelectedMask({});
                        }
                      }}
                    />
                  </td>

                  <td className={`font-bold`}>Document name</td>
                  <td className={`font-bold`}>Date generated</td>
                  <td className={`font-bold`}>Access</td>
                  <td className={`font-bold`}>Uploaded by</td>
                  {data?.transaction.dealership?.hasEnabledCdkDms && (
                    <td className="font-bold">Pushed to DMS</td>
                  )}
                </tr>
              </thead>

              <tbody>
                {items.map((item: DocumentLine) => {
                  const isEven = rowCounter % 2 === 0;
                  rowCounter++;
                  return (
                    <Fragment key={item.id}>
                      <tr
                        key={item.id}
                        className={classNames(
                          "w-full text-black cursor-pointer",
                          {
                            "bg-white": isEven && !selectedMask[item.id],
                            "bg-gray-100": !isEven && !selectedMask[item.id],
                            "bg-gray-300": selectedMask[item.id],
                            "hover:bg-gray-200": !selectedMask[item.id],
                          }
                        )}
                        onClick={() => {
                          handleOpenMultipleDocument([item.id]);
                        }}
                      >
                        <td
                          className="px-4 py-4"
                          width={30}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSelectedMask((mask) => ({
                              ...mask,
                              [item.id]: !mask[item.id],
                            }));
                          }}
                        >
                          <MultiStateCheckbox
                            value={
                              selectedMask[item.id]
                                ? MultiStateCheckboxState.SELECTED
                                : MultiStateCheckboxState.UNSELECTED
                            }
                            mode={"SIMPLE"}
                          />
                        </td>
                        <td className="max-w-xs">
                          <div className="line-clamp-1 text-black">
                            {item.displayTitle}
                          </div>
                        </td>
                        <td>{format(item.createdAt, "dd/MM/yyyy")}</td>
                        <td>
                          {item.access === "BOTH"
                            ? "Dealership / Customer"
                            : item.access === "DEALERSHIP"
                              ? "Dealership"
                              : ""}
                        </td>
                        <td>{item.name}</td>
                        {data?.transaction.dealership?.hasEnabledCdkDms && (
                          <td>
                            {/**
                             * TODO: We're gonna use the badge component for that
                             */}
                            <div
                              className={classNames(
                                "flex rounded-xl px-2 text-gray-800 w-fit",
                                {
                                  "bg-yellow-300":
                                    item.cdkDealJacketStatus &&
                                    CdkDealJacketStatus.parse(
                                      item.cdkDealJacketStatus
                                    ) === "STAGED_FOR_PUSHING",
                                  "bg-green-300":
                                    item.cdkDealJacketStatus &&
                                    CdkDealJacketStatus.parse(
                                      item.cdkDealJacketStatus
                                    ) === "PUSHED",
                                }
                              )}
                            >
                              {item.cdkDealJacketStatus
                                ? getHumanReadableCdkDealJacketStatus(
                                    CdkDealJacketStatus.parse(
                                      item.cdkDealJacketStatus
                                    )
                                  )
                                : "-"}
                            </div>
                          </td>
                        )}
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex flex-row justify-end gap-8 items-center">
            <p className=" text-sm">Total Documents: {totalItem}</p>
            <DealJacketPageNav
              currentPage={currentPage}
              totalPages={totalPages}
              onNextPage={handleNextPage}
              onPrevPage={handlePreviousPage}
            />
          </div>
        </div>
      )}
    </>
  );
}
