import AlertIcon from "./icons/AlertIcon";
import CheckMarkIcon from "./icons/CheckMarkIcon";
import CircleLineThroughIcon from "./icons/CircleLineThroughIcon";
import CrossIcon from "./icons/CrossIcon";

type Props = {
  val?: "UNKNOW" | "POSITIVE" | "NEGATIVE" | "ALERT";
};

/**
 * A semantic version of the data indicator, with each status icon enclosed in a circular background.
 *
 * @param param0
 * @returns
 */
export default function StatusIndicator({ val }: Props) {
  return (
    <>
      {val === "UNKNOW" && (
        <div className="w-6 h-6 p-1 rounded-full bg-gray-100">
          <CircleLineThroughIcon className="aspect-square text-gray-300" />
        </div>
      )}
      {val === "POSITIVE" && (
        <div className="w-6 h-6 p-1 rounded-full bg-green-100">
          <CheckMarkIcon className="aspect-square text-success" />
        </div>
      )}
      {val === "NEGATIVE" && (
        <div className="w-6 h-6 p-1 rounded-full bg-red-100">
          <CrossIcon className="aspect-square text-error" />
        </div>
      )}
      {val === "ALERT" && (
        <div className="w-6 h-6 p-1 rounded-full bg-yellow-100">
          <AlertIcon className="aspect-square text-yellow-500" />
        </div>
      )}
    </>
  );
}
