import { FC } from 'react';

const CarInspectionIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 122.88 87"
    >
      <title>Car inspection icon</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M9,30.31C-.72,25.37.4,19.86,10.17,20.44l2.18,4.1,4.51-14C18.63,5,21.58,0,27.37,0H85c5.78,0,9.11,4.91,10.51,10.51l3.38,13.6,2-3.67c10-.59,10.93,5.24.34,10.27a27.13,27.13,0,0,0-4.4-1.27,28.47,28.47,0,0,0-5.46-.54,27.67,27.67,0,0,0-5.45.54,27.09,27.09,0,0,0-5.3,1.63,27.92,27.92,0,0,0-4.86,2.6,28.6,28.6,0,0,0-4.28,3.47A28,28,0,0,0,68,41.42a27.91,27.91,0,0,0-4.23,21.07,28.46,28.46,0,0,0,1.4,4.74l.22.57c.12.29.24.57.37.86H21.29V71.3A3.3,3.3,0,0,1,18,74.58H4A3.3,3.3,0,0,1,.7,71.3V63.72a4.38,4.38,0,0,1,0-.51C-.33,49.6-1.82,37.33,9,30.31Zm85.9,6A22.27,22.27,0,0,1,114,70.05l8.62,9.39a1.07,1.07,0,0,1-.06,1.51l-6.33,5.77a1.06,1.06,0,0,1-1.5-.06l-8.25-9.07a22,22,0,0,1-5.11,2.29,22.44,22.44,0,0,1-6.44,1,22.12,22.12,0,0,1-8.51-1.69,22.43,22.43,0,0,1-7.23-4.83l0-.06a22.41,22.41,0,0,1-4.78-7.17A22.26,22.26,0,0,1,94.91,36.31ZM107.47,46a17.83,17.83,0,0,0-5.77-3.86l-.06,0a17.67,17.67,0,0,0-6.73-1.32A17.63,17.63,0,0,0,82.35,46a17.62,17.62,0,0,0-3.86,5.77l0,0A17.78,17.78,0,0,0,101.7,75a18.15,18.15,0,0,0,5.77-3.86,17.8,17.8,0,0,0,3.86-19.35A17.93,17.93,0,0,0,107.47,46Zm-80.57.45L14.45,44.89c-2.94-.33-3.73.91-2.72,3.44l1.34,3.27a4.84,4.84,0,0,0,1.68,1.88,5.86,5.86,0,0,0,2.79.77l11.11.09c2.68,0,3.84-1.08,3-3.55a6,6,0,0,0-4.75-4.33ZM17,27.63H93.92l-3.4-14.1c-.93-4.29-3.6-8-8-8H29.28c-4.4,0-6.66,3.81-8,8L17,27.63v0Z"
      />
    </svg>
  );
};

export default CarInspectionIcon;
