import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Spinner from "~/components/Spinner";
import { gqlQueryClient } from "~/lib/backend";
import { formatPhoneNumber } from "~/lib/helpers";
import { consumerDatabaseSelector } from "~/query/consumerDatabaseQuery";
import { Link, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import ConsumerDatabaseTransactionCard from "../_components/ConsumerDatabaseTransactionCard";
import CustomerProfileCard from "../_components/CustomerProfileCard";
import { getLatestResidentialAddress } from "./_customerDatabaseUtil";

const ConsumerDatabase: FC = () => {
  const { userId } = useParams("/dashboard/customers/:userId");

  const dealership = useAtomValue(dealershipAtom);
  const dealershipId = dealership?.activeDealershipPerms.dealershipId;

  const { data } = useQuery({
    enabled: Boolean(dealershipId),
    queryKey: ["userData", userId],
    queryFn: async () =>
      // dealerShipId is defined here, as the query is otherwise disabled.
      gqlQueryClient()(
        consumerDatabaseSelector(userId, dealershipId ?? "never-happens")
      ),
  });

  const hasData = Boolean(data);
  const result = data;
  const userData = result?.userDealership?.user;
  const customers = userData?.customers?.edges;
  const addressData = customers
    ? getLatestResidentialAddress(customers)
    : undefined;
  const transactionHistoryData = customers?.map((n) => n.node);

  return (
    <>
      {!hasData && (
        <div className="flex relative flex-grow justify-center items-center">
          <Spinner />
        </div>
      )}
      {hasData && (
        <div className="flex flex-col space-y-12 w-full">
          <BreadCrumbsContainer>
            <BreadCrumb title="Transaction">
              <Link to={"/dashboard/customers"}>Customers</Link>
            </BreadCrumb>

            <BreadCrumb title="User">
              <span>
                {userData?.firstName ?? ""} {userData?.lastName ?? ""}{" "}
              </span>
            </BreadCrumb>
          </BreadCrumbsContainer>

          <CustomerProfileCard
            email={userData?.email ?? "No Email"}
            // TODO: Figure out what to do now when profile pics are on the customer
            // latestProfilePictureUrl={userData?.profilePicture?.url}
            firstName={userData?.firstName ?? ""}
            lastName={userData?.lastName ?? ""}
            phoneNumber={
              userData?.phoneNumber
                ? formatPhoneNumber(userData?.phoneNumber)
                : "No Phone Number"
            }
            residentialAddress={addressData?.fullAddress ?? "No Address"}
          />

          <div className="flex flex-col space-y-2 items-start w-full">
            <h1 className="text-heading-2 font-normal text-tertiary mb-4 px-1">
              Transactions
            </h1>

            {transactionHistoryData?.map((customer) => {
              return (
                <ConsumerDatabaseTransactionCard
                  customer={customer}
                  key={customer.transaction.id}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ConsumerDatabase;
