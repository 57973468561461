import { useQuery } from "@tanstack/react-query";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { useAtomValue } from "jotai";
import { FC } from "react";
import DataIndicatorV2 from "~/components/DataIndicatorV2";
import { gqlQueryClient } from "~/lib/backend";
import { dealershipAtom } from "~/state";

const TransactionCompliance: FC<{
  transactionId: string;
  hasIdUploaded: boolean;
  hasValidId: boolean;
  redFlagCheckPositive: boolean;
}> = ({ transactionId, hasIdUploaded, hasValidId, redFlagCheckPositive }) => {
  const ds = useAtomValue(dealershipAtom);
  const canSeeCreditData =
    ds?.activeDealershipPerms &&
    permissionChecker("viewCreditReports", ds.activeDealershipPerms);
  const prequal = useQuery({
    queryKey: ["compliance", transactionId],
    queryFn: async () => {
      return await gqlQueryClient()({
        transaction: [
          {
            id: transactionId,
          },
          {
            buyer: {
              prequalApplications: [
                { first: 1 },
                {
                  edges: {
                    __directives: `@include(if:${canSeeCreditData})`,
                    node: {
                      newestReportPrequalify: {
                        score: true,
                        resultCode: true,
                      },
                      newestReportOfac: {
                        ofacStatus: true,
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      });
    },
  });

  const ofacStatus = canSeeCreditData
    ? prequal.data?.transaction.buyer?.prequalApplications.edges.at(0)?.node
        .newestReportOfac?.ofacStatus
    : undefined;

  return (
    <div className="rounded-2xl bg-white shadow-md p-8 space-y-8 size-full">
      <div className="flex flex-row space-x-2 items-center">
        <h2 className="text-heading-1">Compliance</h2>
      </div>

      <div className="grid grid-cols-3 gap-4">
        {/**
         * ID
         */}
        <div className="flex flex-row justify-start items-center">
          <DataIndicatorV2 val={hasIdUploaded ? "positive" : "negative"} />

          <div className="text-body text-dark-gray pl-6">ID</div>
        </div>

        {/**
         * ID verification
         */}
        <div className="flex flex-row justify-start items-center pl-6">
          <DataIndicatorV2 val={hasValidId ? "positive" : "negative"} />
          <div className="text-body text-dark-gray pl-6">ID Verification</div>
        </div>

        <div />

        {/**
         * Red flag
         */}
        <div className="flex flex-row justify-start items-center">
          <DataIndicatorV2
            val={redFlagCheckPositive ? "positive" : "negative"}
          />

          <div className="text-body text-dark-gray pl-6">Red Flag</div>
        </div>

        {/**
         * OFAC
         */}
        <div className="flex flex-row justify-start items-center pl-6">
          <DataIndicatorV2
            val={
              ofacStatus
                ? ofacStatus === "Clear"
                  ? "positive"
                  : "negative"
                : "warn"
            }
          />

          <div className="text-body text-dark-gray pl-6">OFAC</div>
        </div>

        <div />
      </div>

      {/* {!isCoBuyer && (
        <Button variant="SECONDARY" size="SMALL" onClick={handleShowId}>
          View ID
        </Button>
      )} */}
    </div>
  );
};

export default TransactionCompliance;
