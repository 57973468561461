import { UpdateVehicleSchema } from "@thedealersconcierge/lib/codecs/schema/vehicle";
import { VehicleRoleType } from "@thedealersconcierge/lib/codecs/tdc";
import { gqlMutationClient } from "~/lib/backend";

const updateVehicleAction = async (
  transactionId: string,
  vehicleType: VehicleRoleType,
  vehicleId: string,
  vehicle: UpdateVehicleSchema
) => {
  const resp = await gqlMutationClient()({
    updateVehicle: [
      {
        transactionId,
        vehicleType,
        vehicleId,
        vehicle,
      },
      {
        __typename: true,
        "...on MutationUpdateVehicleSuccess": {
          data: {
            status: true,
          },
        },
        "...on GraphQLError": {
          message: true,
        },
      },
    ],
  });

  if (resp.updateVehicle.__typename === "GraphQLError") {
    throw new Error(resp.updateVehicle.message);
  }
};

export default updateVehicleAction;
