import { Routes } from "@generouted/react-router";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "jotai";
import React from "react";
import ReactDOM from "react-dom/client";
import logoutAtion from "./actions/logoutAction";
import "./index.css";
import { queryClient } from "./lib/query";
import store from "./lib/store";

Sentry.init({
  dsn: "https://8c19d4bd0c8d9b921bd9c1324f7ba7e7@o4506535389626368.ingest.sentry.io/4506535445397504",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== "development",
});

/**********
 * Log out when the page was closed
 *
 * git checkout -b madsbuch/sc-1274/as-a-dashboard-user-i-want-to-be-logged-out
 *
 * There is a good change that this does not work effectively. Therefore, remove
 * it, if it doesn't work properly
 */
const tabsOpen = Number(localStorage.getItem("tabsOpen"));
localStorage.setItem("tabsOpen", `${tabsOpen + 1}`);

const pageAccessedByReload =
  (window.performance.navigation && window.performance.navigation.type === 1) ||
  window.performance
    .getEntriesByType("navigation")
    .map((nav) => nav.entryType)
    .includes("reload");

//  If there was not previous tabs open, and we did not approach the page by
// reload, that means it was previously closed
if (!pageAccessedByReload && tabsOpen === 0) {
  logoutAtion().then(() => window.location.reload());
}

window.onbeforeunload = () => {
  const tabsOpen = Number(localStorage.getItem("tabsOpen"));
  localStorage.setItem("tabsOpen", `${Math.max(tabsOpen - 1, 0)}`);
};

/**********  END Log out when the page was closed */

// Render our app!
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <Sentry.ErrorBoundary>
      <React.StrictMode>
        <Provider store={store.instance}>
          <QueryClientProvider client={queryClient}>
            <Routes />
          </QueryClientProvider>
        </Provider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>
  );
}
