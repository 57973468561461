import store from "~/lib/store";
import { userPool } from "~/lib/userpool";
import { authStateAtom, cognitoUserAtom } from "~/state";
import { postAuthenticationAction } from "./postAuthenticationAction";

export const cognitoSubmitMfa = (code: string) => {
  return new Promise<void>((resolve, reject) => {
    if (!userPool) {
      throw new Error("No user pool");
    }

    const user = store.instance.get(cognitoUserAtom);

    if (!user) {
      reject(new Error("Login first"));
      return;
    }

    user.sendMFACode(code, {
      async onSuccess(resp) {
        store.instance.set(authStateAtom, {
          accessToken: resp.getAccessToken().getJwtToken(),
          idToken: resp.getIdToken().getJwtToken(),
          refreshToken: resp.getRefreshToken().getToken(),
        });
        await postAuthenticationAction();
        resolve();
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
};
