import { useQuery } from "@tanstack/react-query";
import { Selector } from "~/__generated__/backend/zeus";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import { gqlQueryClient } from "~/lib/backend";
import { Link, useParams } from "~/router";

const selector = (transactionId: string) =>
  Selector("Query")({
    transaction: [
      {
        id: transactionId,
      },
      {
        id: true,
        title: true,
        buyer: {
          prequalApplications: [
            { first: 1 },
            {
              edges: {
                node: {
                  newestReportPrequalify: {
                    reportHtml: true,
                  },
                },
              },
            },
          ],
        },
      },
    ],
  });

export default function CreditReportPage() {
  const { transactionId } = useParams(
    "/dashboard/transaction/:transactionId/credit-report"
  );
  const { data } = useQuery({
    queryKey: ["transaction-credit-report", transactionId],
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryFn: async (context) => gqlQueryClient()(selector(context.queryKey[1])),
  });

  const transaction = data?.transaction;

  const html =
    data?.transaction.buyer?.prequalApplications.edges.at(0)?.node
      .newestReportPrequalify?.reportHtml;

  const dataUrl = `data:text/html;base64,${btoa(html ?? "")}`;

  return (
    <div className="flex flex-col space-y-8 flex-grow">
      <BreadCrumbsContainer>
        <BreadCrumb title="Transaction">
          <Link to={"/dashboard"}>Transactions</Link>
        </BreadCrumb>

        <BreadCrumb title="Users">
          <Link
            to={"/dashboard/transaction/:transactionId"}
            params={{ transactionId }}
          >
            {transaction?.title}
          </Link>
        </BreadCrumb>

        <BreadCrumb title="700 Credit Report" />
      </BreadCrumbsContainer>

      <div className="flex flex-col flex-1 space-y-6">
        <h1 className="text-heading-1">700 Credit Report</h1>
        {html && <iframe src={dataUrl} className="flex-grow w-full"></iframe>}
        {!html && <div>No Report</div>}
      </div>
    </div>
  );
}
