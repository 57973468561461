import { CognitoUser } from "amazon-cognito-identity-js";
import store from "~/lib/store";
import { userPool } from "~/lib/userpool";
import { cognitoUserAtom } from "~/state";

export const forgottenPasswordAction = (email: string) => {
  return new Promise<void>((resolve, reject) => {
    if (!userPool) {
      throw new Error("No user pool");
    }

    // Construct a user
    const user = new CognitoUser({
      Username: email.toLocaleLowerCase().trim(),
      Pool: userPool,
    });

    store.instance.set(cognitoUserAtom, user);

    user.forgotPassword({
      async onSuccess(resp) {
        resolve();
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
};
