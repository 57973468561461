import { FC } from "react";

const AlertIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M9.99984 18.3337C14.6022 18.3337 18.3332 14.6027 18.3332 10.0003C18.3332 5.39795 14.6022 1.66699 9.99984 1.66699C5.39746 1.66699 1.6665 5.39795 1.6665 10.0003C1.6665 14.6027 5.39746 18.3337 9.99984 18.3337Z"
          stroke="#DDAD05"
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 6.66699V10.0003"
          stroke="#DDAD05"
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 13.333H10.0083"
          stroke="#DDAD05"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default AlertIcon;
