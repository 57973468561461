import { Edit } from "react-feather";
import Button from "../design-system-components/Button";
import AssignModal from "./AssignModal";

type transactionRoles =
  | "SALES_MANAGER"
  | "FNI_MANAGER"
  | "SALES_PERSON"
  | "BDC";

type Props = {
  transactionId: string;
  role: transactionRoles;
  currentAssignee?: string;
  onDidAssign?: () => Promise<void> | void;
  disabled?: boolean;
  selectedUserId?: string;
};

export default function StaffField({
  role,
  currentAssignee,
  transactionId,
  onDidAssign,
  disabled,
  selectedUserId,
}: Props) {
  return currentAssignee ? (
    <AssignModal
      onDidAssign={onDidAssign}
      transactionId={transactionId}
      assignRole={role}
      selectedUserId={selectedUserId}
    >
      {({ openModal }) => (
        <div className="group flex flex-row justify-between items-start">
          <div data-test-id={`staff-field-${role}-assignee`}>
            {currentAssignee}
          </div>

          <button
            onClick={openModal}
            className="opacity-0 group-hover:opacity-100"
            data-test-id={`staff-field-${role}-open-modal-existing-assignee`}
          >
            <Edit size={16} />
          </button>
        </div>
      )}
    </AssignModal>
  ) : (
    <AssignModal
      onDidAssign={onDidAssign}
      transactionId={transactionId}
      assignRole={role}
    >
      {({ openModal }) => (
        <div>
          <Button
            onClick={() => {
              openModal();
            }}
            disabled={disabled}
            size="SMALL"
            variant="LINK"
            data-test-id={`staff-field-${role}-open-modal-new-assignee`}
          >
            <p className="m-0">Assign</p>
          </Button>
        </div>
      )}
    </AssignModal>
  );
}
