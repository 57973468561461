import { queryClient } from "~/lib/query";
import store from "~/lib/store";
import meQuery from "~/query/meQuery";
import { dealershipAtom } from "~/state";

export const postAuthenticationAction = async () => {
  const me = await queryClient.fetchQuery(meQuery());
  const first = me.me.dealershipPerms.at(0);

  if (first) {
    store.instance.set(dealershipAtom, {
      activeDealershipPerms: first,
    });
  }
};
