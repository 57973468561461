import store from "~/lib/store";
import { userPool } from "~/lib/userpool";
import { cognitoUserAtom } from "~/state";

export const resetForgottenPasswordAction = (otp: string, password: string) => {
  return new Promise<void>((resolve, reject) => {
    if (!userPool) {
      throw new Error("No user pool");
    }

    // Construct a user
    const user = store.instance.get(cognitoUserAtom);

    if (!user) {
      reject(new Error("Request code first"));
      return;
    }

    user.confirmPassword(otp, password, {
      async onSuccess(resp) {
        resolve();
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
};
