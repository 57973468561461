import { jwtDecode } from "jwt-decode";
import { authStateAtom, isCognitoAuth } from "~/state";
import store from "./store";
import { userPool } from "./userpool";

export const getJwt = async () => {
  const auth = store.instance.get(authStateAtom);
  const user = userPool?.getCurrentUser();
  if (!isCognitoAuth(auth) || !user) {
    throw new Error("Not a Cognito session");
  }

  const payload = jwtDecode(auth.accessToken);
  if (typeof payload === "string" || typeof payload === "undefined") {
    throw new Error("Could not decode access token");
  }
  // It is still valid, we use it right away (Add 5 seconds margin)
  if (payload.exp && payload.exp - 5 > Date.now() / 1000) {
    return auth.accessToken;
  }

  return new Promise((resolve, reject) => {
    user.refreshSession(
      { getToken: () => auth.refreshToken },
      (err, session) => {
        if (err) {
          // We are not logged in anymore
          store.instance.set(authStateAtom, null);
          return;
        }
        store.instance.set(authStateAtom, {
          ...auth,
          idToken: session.getIdToken().getJwtToken(),
          accessToken: session.getAccessToken().getJwtToken(),
        });
        resolve(session.getAccessToken().getJwtToken());
      }
    );
  });
};
