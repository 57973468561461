import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { CdkDealJacketStatus } from "@thedealersconcierge/lib/codecs/tdc";
import { useAtomValue } from "jotai";
import { Fragment, useCallback, useState } from "react";
import { Upload } from "react-feather";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";
import pushDocumentToCdkAction from "~/actions/documents/pushDocumentToCdkAction";
import pushFormSubmissionToCdkAction from "~/actions/formSubmissions/pushFormSubmissionToCdkAction";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Spinner from "~/components/Spinner";
import Button from "~/components/design-system-components/Button";
import FileErrorIcon from "~/components/icons/FileErrorIcon";
import { getReadableFormSubmissionType } from "~/lib/enumReadable";
import { queryClient } from "~/lib/query";
import { Link, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import { viewDocumentQuery } from "./_queries";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const LocationStateSchema = z.object({
  documentIds: z.array(z.string()).nullable().optional(),
});

// This page expects documentIds passed from location.state
// is only responsible to render out the document
// any conditionals or filters should be handled out of this page
const DealJacketViewDocumentPage = () => {
  const { transactionId } = useParams(
    "/dashboard/transaction/:transactionId/dealJacket/document/:documentId"
  );

  const location = useLocation();
  const locationState = LocationStateSchema.parse(location.state);
  const documentIds = locationState.documentIds ?? [];

  const dealership = useAtomValue(dealershipAtom);

  const { data } = useQuery(viewDocumentQuery(transactionId, documentIds));

  const [numPages, setNumPages] = useState<{ [key: string]: number }>({});
  const [isPushingToDms, setIsPushingToDms] = useState(false);

  const handleDocumentLoadSuccess =
    (url: string) =>
    ({ numPages }: { numPages: number }) => {
      setNumPages((prev) => ({ ...prev, [url]: numPages }));
    };

  const transaction = data?.transaction;

  // We know the id is unique, we just don't know who owns it
  const documents = [
    ...(transaction?.buyer?.documents.edges ?? []),
    ...(transaction?.coBuyer?.documents.edges ?? []),
  ];

  const formSubmissions = [
    ...(transaction?.buyer?.formSubmissions.edges ?? []),
    ...(transaction?.coBuyer?.formSubmissions.edges ?? []),
  ];

  const formSubmissionUrls = formSubmissions?.map((el) => el.node) ?? [];
  const documentUrls = documents.map((el) => el.node);
  const urls = [...formSubmissionUrls, ...documentUrls];

  const getTitle = useCallback(() => {
    if (documentIds.length > 1) return `View Document (${documentIds.length})`;

    if (formSubmissions && formSubmissions.length === 1) {
      const n = formSubmissions.at(0)?.node;
      const title = n?.form?.displayTitle ?? "Document";
      const typeStr = n?.type ?? "Document";

      if (["PRE_PURCHASE", "POST_PURCHASE"].includes(typeStr)) {
        return title;
      }
      return getReadableFormSubmissionType(typeStr);
    }
    if (documents.length === 1) {
      return documents.at(0)?.node.title ?? "Document";
    }
    return "View Document";
  }, [formSubmissions?.length]);

  const handlePushToDms = async () => {
    try {
      setIsPushingToDms(true);

      await Promise.all(
        formSubmissions?.map(async (formSubmission) => {
          const resp = await pushFormSubmissionToCdkAction(
            formSubmission.node.id
          );

          if (resp?.pushFormSubmissionToCdk.__typename === "GraphQLError") {
            throw new Error(resp.pushFormSubmissionToCdk.message);
          }
        }) ?? []
      );
      await Promise.all(
        documents.map(async (document) => {
          const resp = await pushDocumentToCdkAction(document.node.id);

          if (resp?.pushDocumentToCdk.__typename === "GraphQLError") {
            throw new Error(resp.pushDocumentToCdk.message);
          }
        })
      );
      await queryClient.resetQueries({
        queryKey: ["transaction-document", transactionId],
      });

      toast.success("Pushed to DMS");
    } catch (error: unknown) {
      captureException(error);
      toast.error(
        "Failed to push to DMS. Please contact support to make sure the dealership is setup correctly."
      );
    } finally {
      setIsPushingToDms(false);
    }
  };

  const stagedDocumentExists = !!urls.find(
    (u) =>
      u.cdkDealJacketStatus &&
      CdkDealJacketStatus.parse(u.cdkDealJacketStatus) === "STAGED_FOR_PUSHING"
  );
  const allDocumentsPushed = !urls.find(
    (u) =>
      !u.cdkDealJacketStatus ||
      CdkDealJacketStatus.parse(u.cdkDealJacketStatus) !== "PUSHED"
  );

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-row justify-between">
        <BreadCrumbsContainer>
          <BreadCrumb title="Transaction">
            <Link to={"/dashboard"}>Transactions</Link>
          </BreadCrumb>

          <BreadCrumb title="User">
            <Link
              to={"/dashboard/transaction/:transactionId"}
              params={{
                transactionId,
              }}
            >
              {transaction?.title}
            </Link>
          </BreadCrumb>

          <BreadCrumb title="Deal Jacket">
            <Link
              to={"/dashboard/transaction/:transactionId/dealJacket"}
              params={{
                transactionId,
              }}
            >
              Deal Jacket
            </Link>
          </BreadCrumb>

          <BreadCrumb title={getTitle()} />
        </BreadCrumbsContainer>
      </div>

      <div className="flex flex-1 flex-row items-start justify-center">
        <div className="flex flex-col w-full max-w-screen-md items-center overflow-scroll">
          {urls.length > 0 ? (
            urls.map((url, index) => (
              <Fragment key={index}>
                {!url.file?.url && (
                  <div className="flex flex-col space-y-2">
                    Failed to load document. This could be because it has not
                    yet been filled out by the customer
                  </div>
                )}
                {url.file?.url && (
                  <Document
                    file={url.file.url}
                    onLoadSuccess={handleDocumentLoadSuccess(url.file.url)}
                    className="flex flex-col space-y-2"
                    loading={
                      <div className="flex w-full min-h-[80vh] justify-center items-center">
                        <Spinner />
                      </div>
                    }
                    error={
                      <div className="flex flex-col w-full min-h-[80vh] justify-center items-center">
                        <div className="flex flex-col space-y-4 items-center">
                          <div className="relative">
                            <FileErrorIcon className="w-20 text-dark-gray" />
                          </div>

                          <div className="text-subtitle text-dark-gray">
                            Failed to load document
                          </div>
                        </div>
                      </div>
                    }
                  >
                    {[...new Array(numPages[url.file.url] || 0)].map(
                      (_, pageIndex) => (
                        <Page
                          key={pageIndex}
                          pageNumber={pageIndex + 1}
                          className="border border-very-light-gray"
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                        />
                      )
                    )}
                  </Document>
                )}
              </Fragment>
            ))
          ) : (
            <div className="flex w-1/3 aspect-square items-center justify-center">
              <Spinner />
            </div>
          )}
        </div>

        {data?.transaction.dealership?.hasEnabledCdkDms &&
          [
            // Maybe it makes sense to share these rules in the TDC package?
            "ADMIN",
            "FNI_MANAGER",
            "SALES_MANAGER",
          ].includes(dealership?.activeDealershipPerms.role ?? "") && (
            <div className="space-y-4">
              <Button
                onClick={handlePushToDms}
                iconLeft={
                  !stagedDocumentExists ? <Upload size={16} /> : undefined
                }
                variant="SECONDARY"
                loading={isPushingToDms}
                disabled={
                  !data.transaction.cdkDmsDealId || stagedDocumentExists
                }
                className="min-w-40"
              >
                <Fragment>
                  {!stagedDocumentExists && <span>Push to DMS</span>}

                  {stagedDocumentExists && <span>Push in progress</span>}
                </Fragment>
              </Button>

              {/**
               * This is a quick implementation. We will use the banner components for this.
               */}
              {!data.transaction.cdkDmsDealId && (
                <div className="border-red-800 text-red-800 border bg-red-200 max-w-xs rounded px-4 py-1">
                  <p>
                    The Deal ID has to be set on the transaction in order to
                    push the document to the DMS.
                  </p>
                </div>
              )}

              {allDocumentsPushed && (
                <div className="border-green-800 text-green-800 border bg-green-200 max-w-xs rounded px-4 py-1">
                  <p className="text-center">Pushed to DMS</p>
                </div>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

export default DealJacketViewDocumentPage;
