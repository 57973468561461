import { Role } from "~/__generated__/backend/zeus";
import { gqlMutationClient } from "~/lib/backend";

const updateUserDealershipRoleAction = async (userId: string, role: Role) => {
  const resp = await gqlMutationClient()({
    updateUserDealershipRole: [
      {
        userId,
        role,
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationUpdateUserDealershipRoleSuccess": {
          data: {
            id: true,
            status: true,
          },
        },
      },
    ],
  });

  if (resp.updateUserDealershipRole.__typename === "GraphQLError") {
    throw new Error(resp.updateUserDealershipRole.message);
  }

  return resp.updateUserDealershipRole.data;
};

export default updateUserDealershipRoleAction;
