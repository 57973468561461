import { z } from "zod";
import { TransactionSource, TransactionStatusSchema } from "./tdc";

export const nullableObject = <TSchema extends z.AnyZodObject>(
  schema: TSchema
) => {
  const entries = Object.entries(schema.shape) as [
    keyof TSchema["shape"],
    z.ZodTypeAny,
  ][];

  const newProps = entries.reduce(
    (acc, [key, value]) => {
      acc[key] = value.nullable();
      return acc;
    },
    {} as {
      [key in keyof TSchema["shape"]]: z.ZodNullable<TSchema["shape"][key]>;
    }
  );

  return z.object(newProps);
};

// Statuses initial values
export const getReadableTransactionStatus = (
  status: TransactionStatusSchema
) => {
  if (!status) return "-";
  const map = {
    REGISTERED: "Registered",
    SIGNED_UP: "Signed Up",
    IN_PROGRESS: "In Progress",

    PREQUAL_SUBMITTED: "Prequal Submitted",
    CREDIT_APP_SUBMITTED: "Credit App Submitted",
    FILE_COMPLETE: "File Complete",
    READY_FOR_FNI: "Ready for F&I",

    DELIVERED: "Delivered",

    // Deprecated
    WEB_LEAD: "Web Lead",
    WEB_APPLICATION: "Web Application",
    DISASSOCIATED: "Dissociated",
    COMPLETED: "Completed",
    NOT_STARTED: "Not Started",
    DEAD: "Dead",
    RULE_90_DAYS: "Locked after 90 days",
  };
  return map[status] ?? "-";
};

export const getHumanReadableTransactionSource = (
  source: TransactionSource
) => {
  const map: { [key in TransactionSource]: string } = {
    WEB_PREQUAL: "Web-Lead",
    WEB_APPLICATION: "Web-Application",
    KIOSK: "Kiosk",
    ADMIN_DASHBOARD: "Dealership dashboard",
  };

  return map[source];
};
