import { SortingState } from "@tanstack/react-table";
import { FinanceType } from "@thedealersconcierge/lib/codecs/tdc";
import {
  FieldOrdering,
  GraphQLTypes,
  InputType,
  Selector,
  TransactionSource,
  TransactionStatus,
} from "../../__generated__/backend/zeus";

export const formatDealType = (dealtype: FinanceType): string => {
  switch (dealtype) {
    case "CASH":
      return "Cash";
    case "FINANCE":
      return "Finance";
    case "LEASE":
      return "Lease";
    case "OUTSIDE_FINANCING":
      return "Outside financing";
    case "UNDECIDED":
      return "Undecided";
    default:
      return dealtype;
  }
};

export type Ordering = {
  firstName?: FieldOrdering;
  lastName?: FieldOrdering;
  createdAt?: FieldOrdering;
  status?: FieldOrdering;
  source?: FieldOrdering;
};

export const transformOrdering = (d: SortingState): Ordering => {
  const ordering: Ordering = {};
  d.forEach((column) => {
    if (column.id === "creationDate")
      ordering.createdAt = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
    if (column.id === "status")
      ordering.status = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
    if (column.id === "firstName")
      ordering.firstName = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
    if (column.id === "lastName")
      ordering.lastName = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
    if (column.id === "source")
      ordering.source = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
  });

  return ordering;
};

export type Pagination = {
  currentPage: number;
  pageSize: number;
  cursor?: string;
  direction: "before" | "after";
};

export const selector = (
  dealershipId: string,
  ordering: Ordering,
  pagination: Pagination,
  searchString: string,
  statusFilter: TransactionStatus[],
  sourceFilter: TransactionSource[],
  assigedStaffIdsFilter: string[]
) =>
  Selector("Query")({
    dealership: [
      { id: dealershipId },
      {
        transactions: [
          {
            searchString: searchString.length > 0 ? searchString : undefined,

            filter: {
              status:
                statusFilter.length > 0
                  ? {
                      in: statusFilter,
                    }
                  : undefined,
              source:
                sourceFilter.length > 0 ? { in: sourceFilter } : undefined,
              OR:
                assigedStaffIdsFilter.length > 0
                  ? [
                      { bdcId: { in: assigedStaffIdsFilter } },
                      { salesPersonId: { in: assigedStaffIdsFilter } },
                      { fniManagerId: { in: assigedStaffIdsFilter } },
                      { salesManagerId: { in: assigedStaffIdsFilter } },
                    ]
                  : undefined,
            },
            first:
              pagination.direction === "after"
                ? pagination.pageSize
                : undefined,
            last:
              pagination.direction === "before"
                ? pagination.pageSize
                : undefined,
            after:
              pagination.direction === "after" ? pagination.cursor : undefined,
            before:
              pagination.direction === "before" ? pagination.cursor : undefined,
            orderby: ordering,
          },
          {
            totalCount: true,
            edges: {
              cursor: true,
              node: {
                id: true,
                createdAt: true,
                status: true,
                source: true,
                financeType: true,
                logs: [
                  // TODO: Make sure that this is the _newest_ log. It is used to
                  // set last activity date
                  { first: 1 },
                  {
                    edges: {
                      node: {
                        createdAt: true,
                      },
                    },
                  },
                ],

                buyer: {
                  userId: true,
                  firstName: true,
                  lastName: true,
                  hasInsurance: true,
                  idCards: {
                    id: true,
                  },
                  hasCoBuyer: true,
                  hasTradeVehicle: true,
                  hasPrequalApplication: true,
                  // Used to determine whether the user has a hard credit application
                  hardCreditApplications: [
                    { first: 1 },
                    {
                      edges: {
                        node: {
                          id: true,
                        },
                      },
                    },
                  ],
                  wantsToPreQualify: true,
                },
                coBuyer: {
                  userId: true,
                  firstName: true,
                  lastName: true,
                },
                salesPerson: {
                  firstName: true,
                  lastName: true,
                },
                fniManager: {
                  firstName: true,
                  lastName: true,
                },
                salesManager: {
                  firstName: true,
                  lastName: true,
                },
                bdc: {
                  firstName: true,
                  lastName: true,
                },
                tradeVehicle: {
                  year: true,
                  model: true,
                  make: true,
                },
                vehicle: {
                  year: true,
                  model: true,
                  make: true,
                },
              },
            },
          },
        ],
      },
    ],
  });

export type QueryResultType = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof selector>
>;

export type Row = {
  financeType?: string;
  firstIdCard?: { id?: string };
  buyerHasInsurance?: boolean;
  vehicle?: string;
  buyerHasTradeVehicle?: boolean;
  buyerHasHardCreditApplications?: boolean;
  buyerHasPrequalApplications?: boolean;
  buyerWantsToPrequalify?: boolean;
  tradeVehicle?: string;
  salesPerson?: string;
  fniManager?: string;
  salesManager?: string;
  bdc?: string;
  transactionId: string;
  creationDate: Date;
  lastActivityDate: Date;
  status: TransactionStatus;
  source: TransactionSource;
  firstName?: string;
  lastName?: string;
  finishedId: boolean;
  finishedPreQual: boolean;
  finishedCredit: boolean;
  buyerUserId?: string;
  coBuyerUserId?: string;
  hasCoBuyer?: boolean;
};

export const dataTransform = (data?: QueryResultType): Row[] => {
  if (!data) {
    return [];
  }
  return data.dealership.transactions.edges
    .filter((t) => !!t)
    .map((transaction): Row => {
      const tn = transaction.node;
      const buyer = tn.buyer;

      return {
        financeType: tn.financeType,
        firstIdCard: buyer?.idCards[0],
        buyerHasInsurance: tn.buyer?.hasInsurance,
        buyerHasTradeVehicle: tn.buyer?.hasTradeVehicle,
        buyerHasHardCreditApplications: tn.buyer?.hardCreditApplications.edges
          .length
          ? true
          : undefined,
        buyerHasPrequalApplications: buyer?.hasPrequalApplication,
        vehicle: tn.vehicle
          ? `${tn.vehicle.year} ${tn.vehicle.make} ${tn.vehicle.model}`
          : undefined,
        tradeVehicle: tn.tradeVehicle
          ? `${tn.tradeVehicle.year} ${tn.tradeVehicle.make} ${tn.tradeVehicle.model}`
          : undefined,
        fniManager: tn.fniManager
          ? `${tn.fniManager.firstName} ${tn.fniManager.lastName}`
          : undefined,
        salesPerson: tn.salesPerson
          ? `${tn.salesPerson.firstName} ${tn.salesPerson.lastName}`
          : undefined,
        salesManager: tn.salesManager
          ? `${tn.salesManager.firstName} ${tn.salesManager.lastName}`
          : undefined,
        bdc: tn.bdc ? `${tn.bdc.firstName} ${tn.bdc.lastName}` : undefined,
        transactionId: tn.id,
        creationDate: new Date(tn.createdAt as Date),
        lastActivityDate:
          (tn.logs.edges.find(() => true)?.node.createdAt as Date) ??
          new Date(),
        status: tn.status,
        source: tn.source,
        firstName: buyer?.firstName,
        lastName: buyer?.lastName,
        coBuyerUserId: tn.coBuyer?.userId,
        hasCoBuyer: tn.buyer?.hasCoBuyer,
        buyerUserId: tn.buyer?.userId,
        finishedId: false,

        finishedCredit: false,
        finishedPreQual: false,
      };
    });
};

// Can be used to extract single element out of array of items
// E.g: Select a type of Customer from a list of Customer
export type ExtractionType<T> = T extends (infer U)[] ? U : never;
