import classNames from "classnames";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ChevronDown } from "react-feather";

export type DropdownOption = {
  label: string;
  value: string;
};

type DropdownProps = {
  options: DropdownOption[];
  onSelect: (selected: string) => void;
  onSearch?: (inputValue: string) => void;
  title?: string;
  placeholder?: string;
  displayFilter?: boolean;
  size?: "MD" | "SM" | "XS";
  value?: string;
  fieldName?: string;
  noBorder?: boolean;
  transparent?: boolean;
};

const Dropdown = ({
  options,
  onSelect,
  onSearch,
  title,
  placeholder = "Select Item",
  displayFilter,
  size = "MD",
  value,
  fieldName,
  noBorder = false,
  transparent = false,
}: DropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target;
    if (!(target instanceof Node)) return;

    if (
      dropdownButtonRef.current &&
      !dropdownButtonRef.current.contains(target) &&
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const handleOptionChange = (option: DropdownOption) => {
    onSelect(option.value);
    setIsDropdownOpen(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const selectedOption = options.find((option) => option.value === value);

  return (
    <div className="relative inline-block text-left w-full">
      {title && (
        <label className="block text-gray-700 font-medium mb-2">{title}</label>
      )}
      <div className="relative">
        <button
          ref={dropdownButtonRef}
          onClick={toggleDropdown}
          className={classNames(
            "flex justify-between rounded-md items-center w-full",
            {
              "bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 border border-gray-300":
                !transparent,
              "bg-transparent hover:text-blue-700": transparent,
              "px-4 py-2 text-base": size === "MD",
              "px-3 py-1.5 text-sm": size === "SM",
              "px-2 py-1 text-xs": size === "XS",
            }
          )}
        >
          {selectedOption ? selectedOption.label : placeholder}
          <ChevronDown className="w-5 h-5 ml-2" />
        </button>
        {isDropdownOpen && (
          <div
            ref={dropdownMenuRef}
            className={classNames(
              "origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white z-20",
              { "border border-gray-300": !noBorder }
            )}
          >
            <div className="flex flex-col p-2 overflow-y-auto max-h-[300px]">
              {displayFilter && (
                <div className="px-2">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Search"
                    className="w-full px-4 py-2 mb-2 border border-gray-300 rounded-md focus:outline-none"
                  />
                </div>
              )}
              <select
                name={fieldName}
                size={options.length}
                className="w-full border-none outline-none overflow-auto"
                onChange={(e) =>
                  handleOptionChange(
                    options.find((option) => option.value === e.target.value)!
                  )
                }
                style={{
                  scrollbarWidth: "thin",
                }}
              >
                {options.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className="px-2 py-2 mb-1 text-sm text-gray-700 bg-white hover:bg-gray-100 cursor-pointer"
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
