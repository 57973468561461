import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "@tanstack/react-form";
import { Fragment, useState } from "react";
import { X } from "react-feather";
import { toast } from "react-toastify";
import {
  CustomerTransactionRole,
  FormSubmissionAccessEmum,
} from "~/__generated__/backend/zeus";
import Button from "~/components/design-system-components/Button";
import RadioButton from "~/components/design-system-components/RadioButton";
import TextInput from "~/components/design-system-components/input/TextInput";
import UploadFileButton from "~/components/design-system-components/input/UploadFileButton";
import { gqlMutationClient } from "~/lib/backend";
import { DealJacketQueryType } from "../_queries/dealJacketQuery";

type UploadDocumentModalProps = {
  children: (props: { openModal: () => void }) => JSX.Element;
  transaction?: DealJacketQueryType["transaction"];
  refetchTransaction: () => {};
};

// Expected form value type
type FormValues = {
  documentTitle: string | null;
  access: FormSubmissionAccessEmum | null;
  file: File | null;
};

const UploadDocumentModal: React.FC<UploadDocumentModalProps> = ({
  children,
  transaction,
  refetchTransaction,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm<FormValues, undefined>({
    defaultValues: {
      documentTitle: null,
      access: null,
      file: null,
    },
  });

  const closeModal = (): void => {
    if (!loading) {
      setIsOpen(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      form.setFieldValue("file", e.target.files[0]);
    }
  };

  // Need to be scoped outside of the tanstack form onSubmit
  // Else the default event will trigger and the page will refresh
  // This caused the Fetch request to always fail since it terminated prematurely
  const handleSubmit = async () => {
    try {
      setLoading(true);

      const values = form.state.values;
      await handleUpload({
        access: values.access,
        documentTitle: values.documentTitle,
        file: values.file,
      });

      setIsOpen(false);
      toast.success("Document uploaded successfully.");
      form.reset();
      if (refetchTransaction) refetchTransaction();
    } catch (error) {
      console.error(error);
      toast.error(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (values: FormValues) => {
    const { file, documentTitle, access } = values;
    if (!file || !transaction || !transaction.buyerId || !access) {
      throw new Error("Incomplete fields");
    }

    const fileExt = file.name.split(".").pop() ?? "unknown";
    const mimeType = file.type ?? "application/octet-stream";

    const pictureUpload = await gqlMutationClient()({
      makeUploadToTransaction: [
        {
          mimeType: mimeType,
          fileExt: fileExt,
          addTo: CustomerTransactionRole.BUYER,
          transactionId: transaction.id,
        },
        {
          __typename: true,
          "...on GraphQLError": { message: true },
          "...on MutationMakeUploadToTransactionSuccess": {
            data: {
              fileId: true,
              uploadUrl: true,
            },
          },
        },
      ],
    });

    const optimisticResp =
      pictureUpload.makeUploadToTransaction.__typename !== "GraphQLError"
        ? pictureUpload.makeUploadToTransaction.data
        : undefined;

    if (!optimisticResp) throw new Error("Failed to get upload URL");

    if (optimisticResp?.uploadUrl) {
      await fetch(optimisticResp.uploadUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "image/png",
          "Content-Length": file.size.toString(),
        },
        body: file,
      });
    }

    try {
      const result = await gqlMutationClient()({
        uploadAdditionalDocumentsToTransaction: [
          {
            category: "OTHER",
            transactionId: transaction.id,
            uploadedDocumentId: optimisticResp.fileId,
            title: documentTitle,
            access: access,
            addTo: CustomerTransactionRole.BUYER,
          },
          {
            __typename: true,
            "...on GraphQLError": { message: true },
            "...on MutationUploadAdditionalDocumentsToTransactionSuccess": {
              data: {
                id: true,
              },
            },
          },
        ],
      });
    } catch (e) {
      console.error(e);
      throw new Error("Failed to upload document");
    }
  };

  return (
    <div>
      {children({ openModal: () => setIsOpen(true) })}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-modal" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h2"
                    className="flex flex-row justify-between items-center"
                  >
                    <label className="text-lg font-medium leading-6 text-gray-900">
                      Upload Document
                    </label>

                    <div
                      className="cursor-pointer px-2 py-2"
                      onClick={closeModal}
                    >
                      <X />
                    </div>
                  </Dialog.Title>

                  <form.Provider>
                    <form
                      onSubmit={form.handleSubmit}
                      className="flex flex-col gap-2 justify-start items-start mt-4"
                    >
                      <label className="block text-sm font-medium text-gray-700">
                        Upload files<span className="text-red-500">*</span>
                      </label>
                      <form.Subscribe>
                        {(form) => {
                          return (
                            <UploadFileButton
                              onChange={handleFileChange}
                              file={form.values.file}
                            />
                          );
                        }}
                      </form.Subscribe>

                      <div className="my-4">
                        <form.Field name={"documentTitle"}>
                          {(field) => {
                            return (
                              <TextInput
                                placeholder="Document title"
                                label="Document title"
                                required
                                value={field.state.value ?? ""}
                                fieldName={"documenTitle"}
                                onChange={(value: string) =>
                                  field.handleChange(value)
                                }
                                assistiveText="Provide a descriptive document title"
                              />
                            );
                          }}
                        </form.Field>
                      </div>

                      <label className="block text-sm font-medium text-gray-700">
                        Access<span className="text-red-500">*</span>
                      </label>
                      <form.Field name="access">
                        {(field) => {
                          return (
                            <div className="flex flex-col space-y-2">
                              <div className="flex items-center">
                                <RadioButton
                                  label="Dealership"
                                  value="DEALERSHIP"
                                  checked={
                                    field.state.value ===
                                    FormSubmissionAccessEmum.DEALERSHIP
                                  }
                                  onChange={() =>
                                    field.setValue(
                                      FormSubmissionAccessEmum.DEALERSHIP
                                    )
                                  }
                                />
                              </div>
                              <div className="flex items-center">
                                <RadioButton
                                  label="Dealership / Customer"
                                  value="DEALERSHIP_CUSTOMER"
                                  checked={
                                    field.state.value ===
                                    FormSubmissionAccessEmum.BOTH
                                  }
                                  onChange={() =>
                                    field.setValue(
                                      FormSubmissionAccessEmum.BOTH
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        }}
                      </form.Field>

                      <div className="mt-4 flex flex-row w-full justify-end space-x-4">
                        <Button
                          onClick={closeModal}
                          disabled={loading}
                          variant="GHOST"
                        >
                          Cancel
                        </Button>
                        <form.Subscribe>
                          {(field) => {
                            return (
                              <Button
                                type="submit"
                                disabled={
                                  !field.values.file ||
                                  !field.values.access ||
                                  !field.values.documentTitle
                                } // Using direct null assertion for sake of readability
                                loading={loading}
                                onClick={handleSubmit}
                              >
                                Upload
                              </Button>
                            );
                          }}
                        </form.Subscribe>
                      </div>
                    </form>
                  </form.Provider>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default UploadDocumentModal;
