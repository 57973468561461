import { RESET } from "jotai/utils";
import { queryClient } from "~/lib/query";
import store from "~/lib/store";
import { authStateAtom, cognitoUserAtom, dealershipAtom } from "~/state";

export default async function logoutAtion() {
  // We can not just clear everything as we don't want to loose the kiosk mode
  store.instance.set(authStateAtom, RESET);
  store.instance.set(dealershipAtom, RESET);

  // Sign out the cognito user
  const user = store.instance.get(cognitoUserAtom);
  user?.signOut();

  // Remove all data that is present cached
  await queryClient.resetQueries();

  // This will reset all atoms that are not stored
  window.location.href = "/";
}
