import classNames from "classnames";
import { format } from "date-fns";
import { FC } from "react";
import ClockIcon from "~/components/icons/ClockIcon";
import { getDateFromUnkown } from "~/lib/helpers";
import { TransactionQueryType } from "../_queries/transactionQuery";

const TransactionHistory: FC<{
  transaction?: TransactionQueryType["transaction"];
  className?: string;
}> = ({ className, transaction }) => {
  // const { data } = useQuery(transactionQuery(transactionId));
  // const transaction = data?.transaction;
  // Watch out when doing pagination. Make sure that we have sufficient elements
  // from all lists to not get shadowing
  const mergedLogs = [
    ...(transaction?.logs.edges ?? []),
    ...(transaction?.buyer?.logs.edges ?? []),
  ].sort(
    (a, b) =>
      getDateFromUnkown(b.node.createdAt).getTime() -
      getDateFromUnkown(a.node.createdAt).getTime()
  );

  return (
    <div
      className={classNames(
        "rounded-2xl bg-white shadow-md p-8 space-y-8",
        className
      )}
    >
      <div className="flex flex-row space-x-2 items-center">
        <div className="relative">
          <ClockIcon className="w-5 aspect-square text-dark-gray" />
        </div>

        <h2 className="text-heading-2 text-very-dark-gray">History</h2>
      </div>

      {mergedLogs.length ? (
        <table className="w-full">
          <thead>
            <tr className="border-b border-light-gray">
              <td className="py-5 px-6 uppercase font-medium w-56">Date</td>

              <td className="py-5 px-6 uppercase font-medium w-56">User</td>

              <td className="py-5 px-6 uppercase font-medium">Action</td>
            </tr>
          </thead>

          <tbody className="px-10">
            {mergedLogs.map((log, idx) => {
              const createdAt = format(
                new Date(getDateFromUnkown(log.node.createdAt)),
                "MM/dd/yy"
              );
              return (
                <tr
                  className="even:bg-very-light-gray even:border even:border-light-gray"
                  key={idx}
                >
                  <td className="py-5 px-6 text-block font-medium text-dark-gray">
                    {createdAt}
                  </td>

                  <td className="py-5 px-6 text-block font-medium text-very-dark-gray">
                    {log.node.executingUser
                      ? `${log.node.executingUser?.firstName ?? ""} ${log.node.executingUser?.lastName ?? ""}`
                      : "System"}
                  </td>

                  <td className="py-5 px-6 text-block font-medium text-very-dark-gray">
                    {log.node.eventDescription}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="text-body text-dark-gray">No transaction logs yet</div>
      )}
    </div>
  );
};

export default TransactionHistory;
