import { CognitoUserPool } from "amazon-cognito-identity-js";
import config from "~/config";

export const userPool =
  config.cognito.userPoolId && config.cognito.clientId
    ? new CognitoUserPool({
        UserPoolId: config.cognito.userPoolId,
        ClientId: config.cognito.clientId,
      })
    : undefined;
