import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import store from "~/lib/store";
import { userPool } from "~/lib/userpool";
import { authStateAtom, cognitoUserAtom } from "~/state";
import { postAuthenticationAction } from "./postAuthenticationAction";

export const cognitoLoginAction = async (
  email: string,
  password: string
): Promise<"OK" | "MFA_REQUIRED" | "NEW_PASSWORD_REQUIRED"> => {
  if (!userPool) {
    throw new Error("Cognito user pool was not loaded");
  }

  // Construct a user
  const user = new CognitoUser({
    Username: email.toLocaleLowerCase().trim(),
    Pool: userPool,
  });

  store.instance.set(cognitoUserAtom, user);

  // We login with email and password here
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  return new Promise<"OK" | "MFA_REQUIRED" | "NEW_PASSWORD_REQUIRED">(
    (resolve, reject) => {
      user.authenticateUser(authDetails, {
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          resolve("NEW_PASSWORD_REQUIRED");
        },
        mfaRequired: () => resolve("MFA_REQUIRED"),
        onFailure: (failure) => {
          reject(failure);
        },
        onSuccess: async (resp, confirmationNecessary) => {
          store.instance.set(authStateAtom, {
            accessToken: resp.getAccessToken().getJwtToken(),
            idToken: resp.getIdToken().getJwtToken(),
            refreshToken: resp.getRefreshToken().getToken(),
          });
          await postAuthenticationAction();
          resolve("OK");
        },
      });
    }
  );
};
