import { SortingState } from "@tanstack/react-table";
import {
  FieldOrdering,
  GraphQLTypes,
  InputType,
  Role,
  Selector,
} from "~/__generated__/backend/zeus";
import { getDateFromUnkown } from "~/lib/helpers";

export type Ordering = {
  firstName?: FieldOrdering;
  lastName?: FieldOrdering;
  createdAt?: FieldOrdering;
};

export const transformOrdering = (d: SortingState): Ordering => {
  const ordering: Ordering = {};
  d.forEach((column) => {
    if (column.id === "creationDate")
      ordering.createdAt = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
    if (column.id === "firstName")
      ordering.firstName = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
    if (column.id === "lastName")
      ordering.lastName = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
  });

  return ordering;
};

export type Pagination = {
  currentPage: number;
  pageSize: number;
  cursor?: string;
  direction: "before" | "after";
};

export const selector = (
  dealershipId: string,
  ordering: Ordering,
  pagination: Pagination,
  searchString: string
) => {
  return Selector("Query")({
    dealership: [
      { id: dealershipId },
      {
        users: [
          {
            filter: { role: { in: [Role.CUSTOMER] } },

            // orderby: ordering,
            // searchString: searchString.length > 0 ? searchString : undefined,

            first:
              pagination.direction === "after"
                ? pagination.pageSize
                : undefined,
            last:
              pagination.direction === "before"
                ? pagination.pageSize
                : undefined,
            after:
              pagination.direction === "after" ? pagination.cursor : undefined,
            before:
              pagination.direction === "before" ? pagination.cursor : undefined,
          },
          {
            totalCount: true,
            edges: {
              cursor: true,
              node: {
                user: {
                  id: true,
                  createdAt: true,
                  firstName: true,
                  lastName: true,
                },
              },
            },
          },
        ],
        // customers: [
        //   {
        //     searchString: searchString.length > 0 ? searchString : undefined,
        //     first:
        //       pagination.direction === "after"
        //         ? pagination.pageSize
        //         : undefined,
        //     last:
        //       pagination.direction === "before"
        //         ? pagination.pageSize
        //         : undefined,
        //     after:
        //       pagination.direction === "after" ? pagination.cursor : undefined,
        //     before:
        //       pagination.direction === "before" ? pagination.cursor : undefined,
        //     orderby: ordering,
        //   },
        //   {
        //     totalCount: true,
        //     edges: {
        //       cursor: true,
        //       node: {
        //         transactionId: true,
        //         userId: true,
        //         firstName: true,
        //         lastName: true,
        //         createdAt: true,
        //         // hasBeenRemovedFromTransaction: true,
        //         // disassociatedRole: true,
        //         // transaction: {
        //         // buyerId: true,
        //         // },
        //       },
        //     },
        //   },
        // ],
      },
    ],
  });
};

export type QueryResultType = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof selector>
>;

export type Row = {
  // transactionId: string;
  userId: string;
  creationDate: Date;
  firstName?: string;
  lastName?: string;
  // hasBeenRemovedFromTransaction: boolean;
  // transaction: {
  //   buyerId?: string;
  // };

  // Feel free to setup a schema for this.
  disassociatedRole?: string;
};

export const dataTransform = (data?: QueryResultType): Row[] => {
  if (!data) {
    return [];
  }
  return data.dealership.users.edges
    .filter((t) => !!t)
    .map((c): Row => {
      const u = c.node.user;
      return {
        // transactionId: c.node.transactionId,
        userId: u.id,
        firstName: u.firstName,
        lastName: u.lastName,
        // hasBeenRemovedFromTransaction: Boolean(
        //   c.node.hasBeenRemovedFromTransaction
        // ),
        // disassociatedRole: c.node.disassociatedRole,
        // transaction: c.node.transaction,

        // Something is wrong with the scalar date types. feel free to fix it
        creationDate: getDateFromUnkown(u.createdAt),
      };
    });
};
