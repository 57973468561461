import { captureException } from "@sentry/react";
import { useSuspenseQueries } from "@tanstack/react-query";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { useAtom } from "jotai";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import {
  FormSubmissionStatus,
  FormSubmissionType,
  TransactionLifecycle,
  TransactionStatus,
} from "~/__generated__/backend/zeus";
import markTransactionAsDeliveredAction from "~/actions/transactions/markTransactionAsDeliveredAction";
import DataIndicatorV2 from "~/components/DataIndicatorV2";
import Button from "~/components/design-system-components/Button";
import { gqlQueryClient } from "~/lib/backend";
import { queryClient } from "~/lib/query";
import { DealershipQueryType } from "~/query/dealershipQuery";
import { dealershipAtom } from "~/state";
import {
  TransactionQueryType,
  refetchTransactionQuery,
} from "../_queries/transactionQuery";
import ReqSignaturesConfirmModal from "./ReqSignaturesConfirmModal";

const TransactionDocuments: FC<{
  transaction: TransactionQueryType["transaction"];
  dealership: DealershipQueryType["dealership"];
}> = ({ transaction, dealership }) => {
  const [isMarkingTransactionAsDelivered, setIsMarkingTransactionAsDelivered] =
    useState(false);
  const [prePurchaseModalIsOpen, setPrePurchaseModalIsOpen] = useState(false);
  const [postPurchaseModalIsOpen, setPostPurchaseModalIsOpen] = useState(false);
  const [activeDealershipPerms] = useAtom(dealershipAtom);

  const [statusQueryPrePurchase, statusQueryPostPurchase] = useSuspenseQueries({
    queries: [
      {
        queryKey: ["statusQueryPrePurchase", transaction.id],
        queryFn: async () =>
          gqlQueryClient()({
            transaction: [
              { id: transaction.id },
              {
                hardCreditApplDmsSubmittedAt: true,
                // To check if they have submitted hard credit applications
                buyer: {
                  hasSubmittedHardCreditApplication: true,
                },
                coBuyer: {
                  hasSubmittedHardCreditApplication: true,
                },
                // Check if there are any missing documents to be signed (for green check mark)
                formSubmissions: [
                  {
                    filter: {
                      type: {
                        equals: FormSubmissionType.PRE_PURCHASE,
                      },
                      status: {
                        not: { equals: FormSubmissionStatus.FINAL },
                      },
                    },
                  },
                  {
                    __directives: `@include(if:${permissionChecker("viewDealJacket", activeDealershipPerms?.activeDealershipPerms)})`,
                    totalCount: true,
                    edges: { node: { id: true, status: true, type: true } },
                  },
                ],
              },
            ],
          }),
      },
      {
        queryKey: ["statusQueryPostPurchase", transaction.id],
        queryFn: async () =>
          gqlQueryClient()({
            transaction: [
              { id: transaction.id },
              {
                // Check if there are any missing documents to be signed (for green check mark)
                formSubmissions: [
                  {
                    filter: {
                      type: {
                        equals: FormSubmissionType.POST_PURCHASE,
                      },
                      status: {
                        not: { equals: FormSubmissionStatus.FINAL },
                      },
                    },
                  },
                  {
                    totalCount: true,
                    edges: { node: { id: true, status: true, type: true } },
                  },
                ],
              },
            ],
          }),
      },
    ],
  });

  /**
   * Completed when they are requested and none are left to sign
   */

  const completedPrePurchaseDocuments =
    Boolean(transaction?.requestedPrePurchaseFormsAt) &&
    statusQueryPrePurchase.data?.transaction?.formSubmissions?.totalCount === 0
      ? "positive"
      : "negative";

  /**
   * Completed when they are requested and none are left to sign
   */

  const completedPostPurchaseDocuments =
    Boolean(transaction?.requestedPostPurchaseFormsAt) &&
    statusQueryPostPurchase?.data?.transaction?.formSubmissions?.totalCount ===
      0
      ? "positive"
      : "negative";

  // Hard credit application is required when finance and lease
  const submittedRequiredHca = Boolean(
    transaction.financeType === "FINANCE" || transaction.financeType === "LEASE"
      ? statusQueryPrePurchase?.data?.transaction?.buyer
          ?.hasSubmittedHardCreditApplication
      : true
  );
  const submittedRequiredHcaCoBuyer = Boolean(
    (transaction.financeType === "FINANCE" ||
      transaction.financeType === "LEASE") &&
      statusQueryPrePurchase.data?.transaction?.coBuyer
      ? statusQueryPrePurchase.data.transaction.coBuyer
          .hasSubmittedHardCreditApplication
      : true
  );

  // Disable if documents has been requested or hard credit application has not been received
  const disablePrePurchaseButton =
    // Disable if we already requested
    Boolean(transaction?.requestedPrePurchaseFormsAt) ||
    // Transaction needs to hold a vehicle
    !Boolean(transaction.vehicle) ||
    // The buyer needs to have submitted credit application
    !submittedRequiredHca ||
    // The co-buyer, if present, needs to have submitted credit application
    !submittedRequiredHcaCoBuyer;

  // same, but for post
  // Note: As prepurchase docs were completed things like a vehicle already exists and should not be
  // tested again.
  const disablePostPurchaseButton =
    Boolean(transaction?.requestedPostPurchaseFormsAt) ||
    completedPrePurchaseDocuments !== "positive";

  const canRequestSignature = permissionChecker(
    "requestSignatures",
    activeDealershipPerms?.activeDealershipPerms
  );
  const handleMarkTransactionAsDeliverd = async () => {
    try {
      setIsMarkingTransactionAsDelivered(true);

      await markTransactionAsDeliveredAction(transaction.id);
      await queryClient.resetQueries({
        queryKey: ["transaction", transaction.id],
      });

      toast.success("Marked transaction as Delivered");
    } catch (error) {
      captureException(error);
      toast.error("Failed to mark transaction as Delivered");
    } finally {
      setIsMarkingTransactionAsDelivered(false);
    }
  };

  return (
    <>
      <ReqSignaturesConfirmModal
        forLifeCycle={TransactionLifecycle.PRE_PURCHASE}
        transaction={transaction}
        isOpen={prePurchaseModalIsOpen}
        transactionId={transaction.id}
        onError={(msg) => {
          toast.error(`An error happened: ${msg}`);
          setPrePurchaseModalIsOpen(false);
        }}
        onSuccess={async () => {
          toast.success(
            "Successfully requested signatures for pre-purchase documents"
          );
          setPrePurchaseModalIsOpen(false);
          await Promise.all([
            refetchTransactionQuery(transaction.id),
            statusQueryPrePurchase.refetch(),
          ]);
        }}
        onCancel={() => setPrePurchaseModalIsOpen(false)}
      />

      <ReqSignaturesConfirmModal
        forLifeCycle={TransactionLifecycle.POST_PURCHASE}
        transaction={transaction}
        isOpen={postPurchaseModalIsOpen}
        transactionId={transaction.id}
        onError={(msg) => {
          toast.error(`An error happened: ${msg}`);
          setPostPurchaseModalIsOpen(false);
        }}
        onSuccess={async () => {
          toast.success(
            "Successfully requested signatures for post-purchase documents"
          );
          setPostPurchaseModalIsOpen(false);
          await Promise.all([
            refetchTransactionQuery(transaction.id),
            statusQueryPostPurchase.refetch(),
          ]);
        }}
        onCancel={() => setPostPurchaseModalIsOpen(false)}
      />

      {dealership.hasEnabledComplianceForms && (
        <div className="rounded-2xl bg-white shadow-md p-8 space-y-8">
          <div className="flex flex-row justify-between">
            <h2 className="text-heading-1">Forms</h2>

            {!dealership.hasEnabledPostPurchaseDocs &&
              transaction.status === TransactionStatus.READY_FOR_FNI && (
                <Button
                  variant="SECONDARY"
                  loading={isMarkingTransactionAsDelivered}
                  onClick={handleMarkTransactionAsDeliverd}
                >
                  Mark as "Delivered"
                </Button>
              )}
          </div>

          {/* In the future, maybe an error message? */}
          {/* <p className="text-sm font-light italic">
            The dealership does not have compliance forms activated. Contact The
            Dealers Concierge for more information.
          </p> */}

          <>
            {!submittedRequiredHca && (
              <p className="text-sm font-light italic">
                Requesting additional signatures will be enabled when the hard
                credit application has been submitted
              </p>
            )}

            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-5 items-center">
                {/**
                 * Pre-purchase forms
                 */}
                <div className="relative">
                  <DataIndicatorV2 val={completedPrePurchaseDocuments} />
                </div>

                <div className="text-body col-span-3 text-dark-gray">
                  Pre-Purchase Forms
                </div>

                {canRequestSignature && (
                  <Button
                    variant="GHOST"
                    onClick={() => setPrePurchaseModalIsOpen(true)}
                    disabled={disablePrePurchaseButton}
                  >
                    <span className="min-w-48">Request Signatures</span>
                  </Button>
                )}
              </div>

              {dealership.hasEnabledPostPurchaseDocs && (
                <div className="grid grid-cols-5 items-center">
                  {/**
                   * Post-purchase forms
                   */}
                  <div className="relative">
                    <DataIndicatorV2 val={completedPostPurchaseDocuments} />
                  </div>

                  <div className="text-body text-dark-gray col-span-3">
                    Post-Purchase Forms
                  </div>

                  {canRequestSignature && (
                    <Button
                      variant="GHOST"
                      onClick={() => setPostPurchaseModalIsOpen(true)}
                      disabled={disablePostPurchaseButton}
                    >
                      <span className="min-w-48">Request Signatures</span>
                    </Button>
                  )}
                </div>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default TransactionDocuments;
