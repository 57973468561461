import {
  GraphQLTypes,
  InputType,
  Selector,
} from "~/__generated__/backend/zeus";
import { gqlQueryClient } from "~/lib/backend";
import { ExtractionType } from "~/pages/dashboard/_dashboardUtils";

export const homenetDataSelector = (searchStockNumber: string) => {
  return Selector("Query")({
    homenetVehicleData: [
      {
        stockNumberSearchString: searchStockNumber,
      },
      {
        id: true,
        vin: true,
        make: true,
        model: true,
        year: true,
        mileage: true,
        transmission: true,
        fuelType: true,
        stockNumber: true,
        trim: true,
        type: true,
        color: true,
        bodyType: true,
      },
    ],
  });
};

export const fetchHomenetVehicleDataByStockNumber = (
  searchStockNumber: string
) => ({
  queryKey: ["homenetVehicleData", searchStockNumber],
  queryFn: async () => gqlQueryClient()(homenetDataSelector(searchStockNumber)),
});

export type HomenetDataReturn = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof homenetDataSelector>
>;
export type SingleHomenetVehicleData = ExtractionType<
  HomenetDataReturn["homenetVehicleData"]
>;
