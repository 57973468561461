import { captureException } from "@sentry/react";
import { gqlMutationClient } from "~/lib/backend";

const pushFormSubmissionToCdkAction = async (formSubmissionId: string) => {
  try {
    return await gqlMutationClient()({
      pushFormSubmissionToCdk: [
        {
          id: formSubmissionId,
        },
        {
          __typename: true,
          "...on GraphQLError": {
            message: true,
          },
          "...on MutationPushFormSubmissionToCdkSuccess": {
            data: {
              status: true,
            },
          },
        },
      ],
    });
  } catch (e) {
    captureException(e);
  }
};

export default pushFormSubmissionToCdkAction;
