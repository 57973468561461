import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { FC, Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "~/config";
import meQuery from "~/query/meQuery";
import { useNavigate } from "~/router";
import { authStateAtom, dealershipAtom } from "~/state";

const LoadAtoms = () => {
  useAtomValue(authStateAtom);
  useAtomValue(dealershipAtom);

  return null;
};

const NavigationHooks = () => {
  const [delayed, setDelayed] = useState(false);
  const authState = useAtomValue(authStateAtom);
  const navigate = useNavigate();
  const location = useLocation();

  // Automatically redirect if there is not user data
  const { data, isLoading } = useQuery(meQuery());

  // This is a major hack. The problem is, that the atoms are not loading
  // properly as they are not async. Feel free to remove this, when that is
  // fixed.
  useEffect(() => {
    setTimeout(() => setDelayed(true), 100);
  }, []);

  // Navigation effect on auth state
  useEffect(() => {
    if (!delayed) {
      return;
    }
    if (!authState) {
      navigate("/");
    }

    if (!isLoading && !data?.me && location.pathname !== "/") {
      navigate("/");
    }

    if (!isLoading && data?.me && location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [authState, delayed, data, isLoading]);

  return null;
};

/**
 * This hook Chagnes the fav icon in accordance with the environment we are
 * using.
 *
 * @returns
 */
const FavIconHook = () => {
  useEffect(() => {
    let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }

    if (config.prodEnv) {
      link.href = `/logo-${config.prodEnv}.png`;
    } else if (!config.isProduction) {
      link.href = `/logo-local.png`;
    }
  }, []);

  return null;
};

/**
 * Error page
 *
 * @param param0
 * @returns
 */
const MyFallbackComponent: FC<{
  error: any;
  resetErrorBoundary: (...args: unknown[]) => void;
}> = ({ error, resetErrorBoundary }) => {
  return (
    <div
      role="alert"
      className="flex w-full h-full items-center justify-center"
    >
      <div className="h-[400px] w-[400px] shadow-md rounded-md p-4 m-10">
        <h3 className="font-bold">Something went wrong:</h3>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Reset</button>
      </div>
    </div>
  );
};

export default function App() {
  return (
    <ErrorBoundary
      FallbackComponent={MyFallbackComponent}
      onReset={() => {
        window.location.href = "/";
      }}
      resetKeys={["someKey"]}
    >
      <Suspense fallback={<div>Loading app</div>}>
        {/* Load atoms first */}
        <LoadAtoms />

        {/* Then do the rest */}
        <NavigationHooks />

        <FavIconHook />

        <Outlet />

        <ToastContainer position="bottom-right" />
      </Suspense>
    </ErrorBoundary>
  );
}
