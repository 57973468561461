import { captureException } from "@sentry/react";
import { gqlMutationClient } from "~/lib/backend";

const pushDocumentToCdkAction = async (documentId: string) => {
  try {
    return await gqlMutationClient()({
      pushDocumentToCdk: [
        {
          id: documentId,
        },
        {
          __typename: true,
          "...on GraphQLError": {
            message: true,
          },
          "...on MutationPushDocumentToCdkSuccess": {
            data: {
              status: true,
            },
          },
        },
      ],
    });
  } catch (e) {
    captureException(e);
  }
};

export default pushDocumentToCdkAction;
