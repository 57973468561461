import { FC } from "react";
import { AlertTriangle } from "react-feather";

const ErrorBanner: FC<{
  text: string;
}> = ({ text }) => {
  return (
    <div className="flex flex-row w-full px-4 py-2 rounded-md backgr bg-red-100 border-l-8 border-red-300 gap-4">
      <AlertTriangle className="text-red-400 w-6" />
      <p>{text}</p>
    </div>
  );
};

export default ErrorBanner;
