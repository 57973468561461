import { useForm } from "@tanstack/react-form";
import { useState } from "react";
import { cognitoSubmitMfa } from "~/actions/cognitoMfaAction";
import Button from "~/components/Button";
import { useNavigate } from "~/router";

export default function MfaInput() {
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>(null);

  const form = useForm({
    defaultValues: {
      otp: "",
    },
    onSubmit: async (value) => {
      try {
        await cognitoSubmitMfa(value.otp);

        navigate("/dashboard");
      } catch (e: any) {
        setError(e.message);
      }
    },
  });

  return (
    <form.Provider>
      <form
        className="rounded-2xl shadow-2xl w-[444px] flex flex-col space-y-8 items-center p-6"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <h2 className="text-2xl font-bold">SMS Verification</h2>

        <p>Enter the code sent to you via a text message.</p>

        <form.Field
          name="otp"
          children={(field) => (
            <input
              name={field.name}
              value={field.state.value ?? ""}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="One time code"
              type="text"
              className="w-full border border-[#E9EBED] p-4 rounded-lg"
            />
          )}
        />

        {error && (
          <div className="w-full rounded-md bg-red-500 text-white font-semibold p-4">
            {error}
          </div>
        )}

        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <Button type="submit" disabled={!canSubmit} loading={isSubmitting}>
              Submit Code
            </Button>
          )}
        />
      </form>
    </form.Provider>
  );
}
