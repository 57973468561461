import { captureException } from "@sentry/react";
import { useForm } from "@tanstack/react-form";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { FinanceType } from "@thedealersconcierge/lib/codecs/tdc";
import { useAtom } from "jotai";
import { FC, Fragment, useState } from "react";
import { toast } from "react-toastify";
import { GraphQLTypes } from "~/__generated__/backend/zeus";
import updateTransactionAction from "~/actions/transactions/updateTransactionAction";
import EditValueButton from "~/components/EditValueButton";
import Modal from "~/components/Modal";
import StaffField from "~/components/StaffButton/StaffField";
import Button from "~/components/design-system-components/Button";
import { useConfirmationModal } from "~/components/design-system-components/ConfirmationModal";
import Dropdown from "~/components/design-system-components/Dropdown";
import {
  MultiStateCheckbox,
  MultiStateCheckboxState,
} from "~/components/design-system-components/MultiStateCheckbox";
import TextInput from "~/components/inputs/TextInput";
import financeTypeOptions from "~/config/formSelectionOptions/financeTypeOptions";
import { useModals, useNavigate } from "~/router";
import { dealershipAtom } from "~/state";
import FormattedNumber from "../../../../../components/FormattedNumber";
import {
  TransactionQueryType,
  refetchTransactionQuery,
} from "../_queries/transactionQuery";
import DisplayOnHoverWrapper from "./DisplayOnHoverWrapper";

const TransactionDealCard: FC<{
  transaction: TransactionQueryType["transaction"];
}> = ({ transaction }) => {
  const navigate = useNavigate();
  const modals = useModals();
  const { showModal, ConfirmationModalComponent } = useConfirmationModal();
  const [dealership] = useAtom(dealershipAtom);

  const [showDealNumberModal, setShowDealNumberModal] = useState(false);
  const [isUpdatingDealNumber, setIsUpdatingDealNumber] = useState(false);

  const customer = transaction.buyer;
  const coBuyer = transaction.coBuyer;
  const transactionId = transaction.id;

  const stockNumber =
    transaction.vehicle?.stockNumber ?? transaction.tradeVehicle?.stockNumber;
  const cdkDmsDealId = transaction.cdkDmsDealId;

  const salesPerson = transaction.salesPerson;
  const fniManager = transaction.fniManager;
  const salesManager = transaction.salesManager;
  const bdc = transaction.bdc;

  const financeType = transaction.financeType
    ? FinanceType.parse(transaction.financeType)
    : undefined;

  const isLeaseBuyOut = transaction.isLeaseBuyOut;

  const handleDidAssign = async () => {
    await refetchTransactionQuery(transactionId);
    toast.success("Staff updated");
  };

  const handleOpenDealScenarios = () => {
    if (!customer) return;
    modals.open("/dashboard/transaction/[transactionId]/dealScenarios", {
      params: { transactionId, userId: customer.userId },
    });
  };

  const handleGoToDealJacket = () => {
    navigate("/dashboard/transaction/:transactionId/dealJacket", {
      params: { transactionId },
    });
  };
  const handleOpenDealNumberEditModal = () => {
    setShowDealNumberModal(true);
  };
  const handleCloseDealNumberEditModal = () => {
    setShowDealNumberModal(false);
  };
  const cdkDmsDealIdForm = useForm({
    defaultValues: {
      cdkDmsDealId: cdkDmsDealId ?? "",
    },
    onSubmit: async (values) => {
      setIsUpdatingDealNumber(true);

      await updateTransactionAction({ ...values, id: transactionId });
      await refetchTransactionQuery(transactionId);

      setIsUpdatingDealNumber(false);
      handleCloseDealNumberEditModal();
    },
  });

  const handleUpdateFinanceType = async (newFinanceType: string) => {
    try {
      const financeType = FinanceType.parse(newFinanceType);
      if (!financeType) {
        toast.error("Invalid finance type");
        return;
      }

      const confirmed = await showModal({
        content: "Are you sure you want to change the finance type?",
        title: "Change Finance Type",
      });

      if (confirmed) {
        await updateTransactionAction({
          id: transactionId,
          financeType: financeType,
        });
        await refetchTransactionQuery(transactionId);
        toast.success("Successfully updated finance type");
      }
    } catch (e) {
      captureException(`Failed to update finance type: ${e}`);
      toast.error("Failed to update finance type, contact admin");
    }
  };

  const handleUpdateLBO = async (checkState: MultiStateCheckboxState) => {
    try {
      const newLBO =
        checkState === MultiStateCheckboxState.SELECTED ? true : false;

      const confirmed = await showModal({
        content: "Are you sure you want to change LBO?",
        title: "Change LBO",
      });

      if (confirmed) {
        await updateTransactionAction({
          id: transactionId,
          isLeaseBuyOut: newLBO,
        });
        await refetchTransactionQuery(transactionId);
        toast.success("Successfully updated LBO");
      }
    } catch (e) {
      captureException(`Failed to update LBO:  ${e}`);
      toast.error("Failed to update LBO, contact admin");
    }
  };

  const handleUpdate = (
    updatePayload: GraphQLTypes["UpdateTransactionInput"]
  ): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        await updateTransactionAction(updatePayload);
        await refetchTransactionQuery(transactionId);
        resolve();
      } catch (e) {
        captureException(`Failed to update transaction: ${e}`);
        reject(e);
      }
    });
  };

  const canViewDealJacket = permissionChecker(
    "viewDealJacket",
    dealership?.activeDealershipPerms
  );

  return (
    <Fragment>
      <Modal
        title="Edit Deal Number"
        isOpen={showDealNumberModal}
        onClose={handleCloseDealNumberEditModal}
        className="flex flex-col p-6 space-y-6"
      >
        <cdkDmsDealIdForm.Provider>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void cdkDmsDealIdForm.handleSubmit();
            }}
          >
            <cdkDmsDealIdForm.Field name="cdkDmsDealId">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    labelText="Deal number"
                    placeholder="Deal number"
                    subtitleText="Deal number"
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                  />
                );
              }}
            </cdkDmsDealIdForm.Field>
          </form>

          <div className="flex flex-row space-x-4 justify-end">
            <Button variant="GHOST" disabled={isUpdatingDealNumber}>
              Cancel
            </Button>

            <Button
              onClick={cdkDmsDealIdForm.handleSubmit}
              loading={isUpdatingDealNumber}
            >
              Save
            </Button>
          </div>
        </cdkDmsDealIdForm.Provider>
      </Modal>

      <div className="rounded-2xl bg-white shadow-md p-8 flex flex-col justify-between mb-4 gap-4">
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row items-center">
            <h2 className="text-heading-1">Deal</h2>

            {coBuyer?.userId && (
              <div className="ml-2 pl-2  border-l-2">
                <Button variant="LINK" onClick={handleOpenDealScenarios}>
                  <p className="text-heading-2 h-full">Deal Scenarios</p>
                </Button>
              </div>
            )}
          </div>

          {canViewDealJacket && (
            <Button
              size="SMALL"
              onClick={handleGoToDealJacket}
              variant="SECONDARY"
            >
              Deal Jacket
            </Button>
          )}
        </div>

        <div className="w-full grid grid-cols-8 mb-4 border-b-2 pb-8">
          <div className="col-span-5 grid grid-cols-4 gap-16">
            <div className="flex flex-col gap-4">
              <p className="text-body text-dark-gray">Finance Type</p>
              <div className="text-body">
                <Dropdown
                  onSelect={handleUpdateFinanceType}
                  options={financeTypeOptions}
                  placeholder="Finance Type"
                  size="XS"
                  value={financeType}
                />
              </div>
            </div>

            {transaction.dealership?.hasEnabledCdkDms && (
              <div className="flex flex-col gap-4">
                <p className="text-body text-dark-gray">Deal Number</p>

                <div className="flex flex-row h-fit items-center justify-between">
                  <p className="text-body">
                    {cdkDmsDealId?.length ? cdkDmsDealId : "-"}
                  </p>

                  <Button
                    variant="LINK"
                    onClick={handleOpenDealNumberEditModal}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            )}

            <div className="flex flex-col gap-4">
              <p className="text-body text-dark-gray">Stock Number</p>
              <p className="text-body">{stockNumber ?? "-"}</p>
            </div>

            <div className="flex flex-col gap-4">
              <p className="text-body text-dark-gray">LBO</p>
              <div className="text-body">
                <MultiStateCheckbox
                  mode="SIMPLE"
                  value={
                    isLeaseBuyOut
                      ? MultiStateCheckboxState.SELECTED
                      : MultiStateCheckboxState.UNSELECTED
                  }
                  onChange={handleUpdateLBO}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-8 gap-12 lg:gap-40">
          <div className="col-span-5">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 items-start">
              {/* ALL DEALS GROUP */}
              <div className="flex flex-col gap-4 ">
                <p className="font-semibold text-sm">All Deals</p>

                <div className="grid grid-cols-2">
                  <p className="text-body text-dark-gray">Consumer Rebates</p>
                  <div className="flex flex-row gap-2 items-center justify-between group w-full">
                    <FormattedNumber
                      value={(transaction.consumerRebatesInUsdCents ?? 0) / 100}
                      suffix="$"
                    />
                    <DisplayOnHoverWrapper>
                      <EditValueButton
                        onSubmit={async (newValue) => {
                          const valueInCents = !isNaN(parseFloat(newValue))
                            ? parseFloat(newValue) * 100
                            : null;
                          if (!valueInCents) return;
                          await handleUpdate({
                            consumerRebatesInUsdCents: valueInCents,
                            id: transactionId,
                          });
                        }}
                        type="number"
                        value={
                          (transaction.consumerRebatesInUsdCents ?? 0) / 100
                        }
                        title="Edit Consumer Rebates"
                        suffix="$"
                        min={0}
                        subtitle="Consumer Rebates"
                      />
                    </DisplayOnHoverWrapper>
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <p className="text-body text-dark-gray">Down Payment</p>
                  <div className="flex flex-row gap-2 items-center w-full justify-between group w-full">
                    <FormattedNumber
                      value={(transaction.downPaymentInUsdCents ?? 0) / 100}
                      suffix="$"
                    />
                    <DisplayOnHoverWrapper>
                      <EditValueButton
                        onSubmit={async (newValue) => {
                          const valueInCents = !isNaN(parseFloat(newValue))
                            ? parseFloat(newValue) * 100
                            : null;
                          if (!valueInCents) return;
                          await handleUpdate({
                            downPaymentInUsdCents: valueInCents,
                            id: transactionId,
                          });
                        }}
                        type="number"
                        value={(transaction.downPaymentInUsdCents ?? 0) / 100}
                        title="Edit Down Payment"
                        suffix="$"
                        min={0}
                        subtitle="Down Payment"
                      />
                    </DisplayOnHoverWrapper>
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <p className="text-body text-dark-gray">Sales Tax</p>
                  <div className="flex flex-row gap-2 items-center justify-between group w-full">
                    <FormattedNumber
                      value={(transaction.salesTaxInBsp ?? 0) / 100}
                      suffix="%"
                    />
                    <DisplayOnHoverWrapper>
                      <EditValueButton
                        onSubmit={async (newValue) => {
                          const valueInBasisPoints = !isNaN(
                            parseFloat(newValue)
                          )
                            ? parseFloat(newValue) * 100
                            : null;
                          if (!valueInBasisPoints) return;
                          await handleUpdate({
                            salesTaxInBsp: valueInBasisPoints,
                            id: transactionId,
                          });
                        }}
                        min={0}
                        max={20}
                        type="number"
                        value={(transaction.salesTaxInBsp ?? 0) / 100}
                        title="Edit Sales Tax"
                        subtitle="Sales Tax"
                        suffix="%"
                      />
                    </DisplayOnHoverWrapper>
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <div className="flex flex-col">
                    <p className="text-body text-dark-gray">
                      Vehicle Trade Dollar
                    </p>
                    <p className="text-body text-dark-gray">(Trade-In Price)</p>
                  </div>
                  <div className="flex flex-row gap-2 items-center justify-between group w-full">
                    <FormattedNumber
                      value={(transaction.tradeInPriceInUsdCents ?? 0) / 100}
                      suffix="$"
                    />
                    <DisplayOnHoverWrapper>
                      <EditValueButton
                        onSubmit={async (newValue) => {
                          const valueInCents = !isNaN(parseFloat(newValue))
                            ? Math.round(parseFloat(newValue) * 100)
                            : null;
                          if (!valueInCents) return;
                          await handleUpdate({
                            tradeInPriceInUsdCents: valueInCents,
                            id: transactionId,
                          });
                        }}
                        type="number"
                        value={(transaction.tradeInPriceInUsdCents ?? 0) / 100}
                        title="Edit Vehicle Trade Dollar (Trade-In Price)"
                        suffix="$"
                        min={0}
                        subtitle="Vehicle Trade Dollar"
                      />
                    </DisplayOnHoverWrapper>
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <p className="text-body text-dark-gray">We Owe</p>
                  <div className="flex flex-row gap-2 items-center justify-between group w-full">
                    <p className="text-body">
                      {transaction.dealershipCommitment ?? "-"}
                    </p>
                    <DisplayOnHoverWrapper>
                      <EditValueButton
                        onSubmit={async (newValue) => {
                          await handleUpdate({
                            dealershipCommitment: newValue,
                            id: transactionId,
                          });
                        }}
                        type="text"
                        value={transaction.dealershipCommitment ?? ""}
                        title="Edit We Owe"
                        subtitle="We Owe"
                      />
                    </DisplayOnHoverWrapper>
                  </div>
                </div>
              </div>

              {/* RETAIL & LEASE */}
              <div className="flex flex-col gap-4">
                {/* RETAIL GROUP */}
                <div className="flex flex-col gap-4">
                  <p className="font-semibold text-sm">Retail</p>

                  <div className="grid grid-cols-2">
                    <p className="text-body text-dark-gray">
                      Vehicle Sale Price
                    </p>
                    <div className="flex flex-row gap-2 items-center justify-between group w-full">
                      <FormattedNumber
                        value={(transaction.salesPriceInUsdCents ?? 0) / 100}
                        suffix="$"
                      />
                      <DisplayOnHoverWrapper>
                        <EditValueButton
                          onSubmit={async (newValue) => {
                            const valueInCents = !isNaN(parseFloat(newValue))
                              ? Math.round(parseFloat(newValue) * 100)
                              : null;
                            if (!valueInCents) return;
                            await handleUpdate({
                              salesPriceInUsdCents: valueInCents,
                              id: transactionId,
                            });
                          }}
                          type="number"
                          value={(transaction.salesPriceInUsdCents ?? 0) / 100}
                          title="Edit Sale Price"
                          suffix="$"
                          min={0}
                          subtitle="Sale Price"
                        />
                      </DisplayOnHoverWrapper>
                    </div>
                  </div>
                </div>

                {/* LEASE GROUP */}
                <div className="flex flex-col gap-4">
                  <p className="font-semibold text-sm">Lease</p>

                  <div className="grid grid-cols-2">
                    <p className="text-body text-dark-gray">Monthly Payment</p>
                    <div className="flex flex-row gap-2 items-center justify-between group w-full">
                      <FormattedNumber
                        value={
                          (transaction.monthlyLeasePaymentInUsdCents ?? 0) / 100
                        }
                        suffix="$"
                      />
                      <DisplayOnHoverWrapper>
                        <EditValueButton
                          onSubmit={async (newValue) => {
                            const valueInCents = !isNaN(parseFloat(newValue))
                              ? parseFloat(newValue) * 100
                              : null;
                            if (!valueInCents) return;
                            await handleUpdate({
                              monthlyLeasePaymentInUsdCents: valueInCents,
                              id: transactionId,
                            });
                          }}
                          type="number"
                          value={
                            (transaction.monthlyLeasePaymentInUsdCents ?? 0) /
                            100
                          }
                          title="Edit Monthly Payment"
                          suffix="$"
                          min={0}
                          subtitle="Monthly Payment"
                        />
                      </DisplayOnHoverWrapper>
                    </div>
                  </div>

                  <div className="grid grid-cols-2">
                    <p className="text-body text-dark-gray">Miles per Year</p>
                    <div className="flex flex-row gap-2 items-center justify-between group w-full">
                      <FormattedNumber
                        value={transaction.milesPerYearInLease}
                        decimalScale={0}
                      />
                      <DisplayOnHoverWrapper>
                        <EditValueButton
                          onSubmit={async (newValue) => {
                            await handleUpdate({
                              milesPerYearInLease: parseInt(newValue),
                              id: transactionId,
                            });
                          }}
                          type="number"
                          value={transaction.milesPerYearInLease}
                          title="Edit Miles per Year"
                          min={0}
                          subtitle="Miles per Year"
                        />
                      </DisplayOnHoverWrapper>
                    </div>
                  </div>

                  <div className="grid grid-cols-2">
                    <p className="text-body text-dark-gray">
                      Lease Terms (Months)
                    </p>
                    <div className="flex flex-row gap-2 items-center justify-between group w-full">
                      <p className="text-body">
                        {transaction.leaseTermInMonths ?? "-"}
                      </p>
                      <DisplayOnHoverWrapper>
                        <EditValueButton
                          onSubmit={async (newValue) => {
                            await handleUpdate({
                              leaseTermInMonths: parseInt(newValue),
                              id: transactionId,
                            });
                          }}
                          type="text"
                          value={transaction.leaseTermInMonths}
                          title="Edit Lease Terms (Month)"
                          min={0}
                          subtitle="Lease Terms (Month)"
                        />
                      </DisplayOnHoverWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-3">
            <p className="font-semibold text-sm mb-4">Staff</p>
            <div className="grid grid-cols-2 lg:grid-cols-2 gap-4">
              <p className="text-body text-dark-gray">Sales Person</p>

              <StaffField
                transactionId={transactionId}
                role="SALES_PERSON"
                onDidAssign={handleDidAssign}
                selectedUserId={salesPerson?.id}
                currentAssignee={
                  salesPerson
                    ? `${salesPerson.firstName} ${salesPerson.lastName}`
                    : undefined
                }
              />

              <p className="text-body text-dark-gray">Sales Manager</p>
              <StaffField
                transactionId={transactionId}
                role="SALES_MANAGER"
                onDidAssign={handleDidAssign}
                selectedUserId={salesManager?.id}
                currentAssignee={
                  salesManager
                    ? `${salesManager.firstName} ${salesManager.lastName}`
                    : undefined
                }
              />

              <p className="text-body text-dark-gray">F&I Manager</p>

              <StaffField
                transactionId={transactionId}
                role="FNI_MANAGER"
                onDidAssign={handleDidAssign}
                selectedUserId={fniManager?.id}
                currentAssignee={
                  fniManager
                    ? `${fniManager.firstName} ${fniManager.lastName}`
                    : undefined
                }
              />

              <p className="text-body text-dark-gray">BDC</p>

              <StaffField
                transactionId={transactionId}
                role="BDC"
                onDidAssign={handleDidAssign}
                selectedUserId={bdc?.id}
                currentAssignee={
                  bdc ? `${bdc.firstName} ${bdc.lastName}` : undefined
                }
              />
            </div>
          </div>
        </div>
      </div>
      {ConfirmationModalComponent}
    </Fragment>
  );
};

export default TransactionDealCard;
