import { useState } from "react";
import config from "~/config";
import ForgottenPassword from "./_components/ForgottenPassword";
import ForgottenPasswordUpdatePassword from "./_components/ForgottenPasswordUpdatePassword";
import MfaInput from "./_components/MfaInput";
import SetNewPassword from "./_components/SetNewPassword";
import SignIn from "./_components/SignIn";
import { UiState } from "./_components/types";

export default function LoginPage() {
  const [uiState, setUiState] = useState<UiState>("SIGN_IN");

  let logo = `/logo-${config.prodEnv ?? "local"}.png`;

  return (
    <main className="flex min-h-dvh flex-col p-24 space-y-8 items-center">
      <div>
        <img src={logo} alt="Logo" />
      </div>

      {uiState === "FORGOT_PASSWORD" && (
        <ForgottenPassword setUiState={setUiState} />
      )}
      {uiState === "FORGOT_PASSWORD_RESET_CODE_SEND" && (
        <ForgottenPasswordUpdatePassword setUiState={setUiState} />
      )}
      {uiState === "MFA_REQUIRED" && <MfaInput />}
      {uiState === "SIGN_IN" && <SignIn setUiState={setUiState} />}
      {uiState === "NEW_PASSWORD_REQUIRED" && (
        <SetNewPassword setUiState={setUiState} />
      )}
      <div className="flex flex-row space-x-6">
        <a
          className="text-blue-500 hover:underline"
          href="https://files.mytdc.net/terms-and-conditions-tdc-digital-dealer-dervices-rev-jan-2024.pdf"
          target="_blank"
        >
          Terms & Conditions
        </a>
        <a
          className="text-blue-500 hover:underline"
          href="https://files.mytdc.net/privacy-policy-dealergenix-jan2024.pdf"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
    </main>
  );
}
