import stringify from "json-stable-stringify";
import { Selector } from "~/__generated__/backend/zeus";
import { gqlQueryClient } from "~/lib/backend";

export const documentSelector = Selector("Document")({
  title: true,
  file: {
    id: true,
    url: true,
  },
  id: true,
  cdkDealJacketStatus: true,
});

export const viewDealJacketCustomerSelector = (documentIds: string[]) =>
  Selector("Customer")({
    documents: [
      { filter: { id: { in: documentIds } } },
      {
        edges: {
          node: documentSelector,
        },
      },
    ],
    formSubmissions: [
      {
        filter: {
          id: {
            in: documentIds,
          },
        },
      },
      {
        edges: {
          node: {
            id: true,
            file: {
              id: true,
              url: true,
            },
            type: true,
            form: {
              displayTitle: true,
            },
            cdkDealJacketStatus: true,
          },
        },
      },
    ],
  });

// Could be refactored and separate by "BUYER" and "COBUYER" for better readability
export const viewDocumentQuery = (
  transactionId: string,
  documentIds: string[]
) => {
  return {
    queryKey: ["transaction-document", transactionId, stringify(documentIds)],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId,
          },
          {
            buyer: viewDealJacketCustomerSelector(documentIds),
            coBuyer: viewDealJacketCustomerSelector(documentIds),
            title: true,
            dealership: {
              hasEnabledCdkDms: true,
            },
            cdkDmsDealId: true,
          },
        ],
      }),
  };
};
