import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { useAtom } from "jotai";
import { FC } from "react";
import { Download, Eye, Printer, Upload, X } from "react-feather";
import Button from "~/components/design-system-components/Button";
import { dealershipAtom } from "~/state";

type ActionBarProps = {
  selectedCount: number;
  onSelectAll: () => void;
  onView: () => void;
  onDownload: (() => void) | (() => Promise<void>);
  onPrint: (() => void) | (() => Promise<void>);
  onPushToDMS: (() => void) | (() => Promise<void>);
  onCloseActionBar: () => void;
  showPushToDms?: boolean;
  isPushingToDms?: boolean;
};

const ActionBar: FC<ActionBarProps> = ({
  selectedCount,
  onSelectAll,
  onView,
  onDownload,
  onPrint,
  onPushToDMS,
  onCloseActionBar,
  showPushToDms,
  isPushingToDms,
}) => {
  const [dealership] = useAtom(dealershipAtom);

  const canDownloadDealJacket = permissionChecker(
    "downloadDealJacket",
    dealership?.activeDealershipPerms
  );

  return (
    <div className="flex items-center justify-between px-6 py-3 bg-blue-100 rounded-md shadow-sm">
      <div className="flex flex-row space-x-4 items-center">
        <div className="text-sm font-medium text-gray-700">
          {selectedCount} Selected
        </div>
        <Button variant="GHOST" onClick={onSelectAll}>
          Select All
        </Button>
      </div>

      <div className="flex flex-row space-x-2 items-center">
        <Button onClick={onView} iconLeft={<Eye size={16} />} variant="GHOST">
          <span>View</span>
        </Button>

        {canDownloadDealJacket && (
          <Button
            onClick={onDownload}
            iconLeft={<Download size={16} />}
            variant="GHOST"
          >
            <span>Download</span>
          </Button>
        )}

        <Button
          onClick={onPrint}
          iconLeft={<Printer size={16} />}
          variant="GHOST"
        >
          <span>Print</span>
        </Button>

        {showPushToDms && (
          <Button
            onClick={onPushToDMS}
            iconLeft={<Upload size={16} />}
            variant="GHOST"
            loading={isPushingToDms}
          >
            <span>Push to DMS</span>
          </Button>
        )}

        <div className="cursor-pointer px-2 py-2" onClick={onCloseActionBar}>
          <X className="text-blue-400 hover:text-blue-600" />
        </div>
      </div>
    </div>
  );
};

export default ActionBar;
