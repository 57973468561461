import { TransactionStatusSchema } from "@thedealersconcierge/lib/codecs/tdc";
import { getReadableTransactionStatus } from "@thedealersconcierge/lib/codecs/util";
import classNames from "classnames";
import { FC } from "react";

// z.literal("REGISTERED"),
// z.literal("SIGNED_UP"),
// z.literal("IN_PROGRESS"),

// z.literal("PREQUAL_SUBMITTED"),
// z.literal("CREDIT_APP_SUBMITTED"),
// z.literal("FILE_COMPLETE"),
// z.literal("READY_FOR_FNI"),

// z.literal("DELIVERED"),

// // Deprecate
// z.literal("WEB_LEAD"), // For web prequal applications
// z.literal("WEB_APPLICATION"), // For web hard credit applications
// z.literal("COMPLETED", { description: "DEPRECATED" }),
// z.literal("DISASSOCIATED", { description: "DEPRECATED" }),
// z.literal("DEAD", { description: "DEPRECATED" }),
// z.literal("RULE_90_DAYS", { description: "DEPRECATED" }),
// z.literal("NOT_STARTED", { description: "DEPRECATED" }),

// TODO: Create and use general badge component
const StatusBadge: FC<{
  status: TransactionStatusSchema;
  className?: string;
  size?: "DEFAULT" | "SMALL";
}> = ({ status, className, size = "DEFAULT" }) => {
  return (
    <div
      className={classNames(
        "flex rounded-lg capitalize",
        {
          "bg-red-300": status === "REGISTERED",
          "bg-red-200": status === "SIGNED_UP",
          "bg-blue-200": status === "IN_PROGRESS",

          "bg-yellow-200": status === "PREQUAL_SUBMITTED",
          "bg-green-100": status === "CREDIT_APP_SUBMITTED",
          "bg-green-200": status === "FILE_COMPLETE",
          "bg-teal-200": status === "READY_FOR_FNI",

          "bg-gray-200": status === "DELIVERED",

          // Deprecated statusses
          border:
            status === "WEB_LEAD" ||
            status === "WEB_APPLICATION" ||
            status === "COMPLETED" ||
            status === "DISASSOCIATED" ||
            status === "DEAD" ||
            status === "RULE_90_DAYS" ||
            status === "NOT_STARTED",

          "px-4 py-2 font-medium": size === "DEFAULT",
          "px-2 py-1 text-sm": size === "SMALL",
        },
        className
      )}
    >
      {getReadableTransactionStatus(status)}
    </div>
  );
};

export default StatusBadge;
