import AlertIcon from "./icons/AlertIcon";
import CheckMarkIcon from "./icons/CheckMarkIcon";
import CrossIcon from "./icons/CrossIcon";

type Props = {
  val: "positive" | "negative" | "warn";
};

// This is marked as v2 since there is still some usage outside of TransactionFile
// The style might not be consistent with the old version of UI so the V2 convetion is used
export default function DataIndicatorV2({ val }: Props) {
  switch (val) {
    case "warn":
      return (
        <div className="w-6 h-6 p-1 rounded-full bg-orange-100">
          <AlertIcon className="aspect-square text-orange-400" />
        </div>
      );
    case "positive":
      return (
        <div className="w-6 h-6 p-1 rounded-full bg-green-100">
          <CheckMarkIcon className="aspect-square text-success" />
        </div>
      );
    case "negative":
      return (
        <div className="w-6 h-6 p-1 rounded-full bg-red-100">
          <CrossIcon className="aspect-square text-error" />
        </div>
      );
  }
}
