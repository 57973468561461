import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react/jsx-runtime";
import { SubmitTo } from "~/__generated__/backend/zeus";
import { gqlMutationClient } from "~/lib/backend";

type Props = {
  isOpen: boolean;
  onError: (msg: string) => void;
  onSuccess: () => void;
  onCancel: () => void;
  transactionId: string;
  submitTo: SubmitTo;
};

export default function ConfirmModal({
  isOpen,
  onError,
  onSuccess,
  onCancel,
  transactionId,
  submitTo,
}: Props) {
  const pushApplication = async () => {
    const resp = await gqlMutationClient()({
      pushHardCreditApplication: [
        {
          transactionId,
          submitTo: submitTo,
        },
        {
          __typename: true,
          "...on GraphQLError": {
            message: true,
          },
          "...on MutationPushHardCreditApplicationSuccess": {
            data: {
              status: true,
            },
          },
        },
      ],
    });
    if (
      resp.pushHardCreditApplication.__typename ===
      "MutationPushHardCreditApplicationSuccess"
    ) {
      onSuccess();
    } else {
      onError(resp.pushHardCreditApplication.message);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-modal" onClose={onCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={
                  "w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-6"
                }
              >
                <div className="py-4 text-center text-heading-3">
                  <Dialog.Title className="">Submit Application?</Dialog.Title>
                </div>

                <p className="py-4 text-center">
                  Are you sure you want to submit the credit application to the
                  Lending?
                </p>

                <button
                  data-test-id="lending-portal-confirm-submit"
                  className="text-secondary-blue py-4 text-center w-full"
                  onClick={pushApplication}
                >
                  Submit
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
