import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Selector } from "~/__generated__/backend/zeus";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Spinner from "~/components/Spinner";
import FileErrorIcon from "~/components/icons/FileErrorIcon";
import { gqlQueryClient } from "~/lib/backend";
import { Link, useParams } from "~/router";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const formSubmissionSelector = Selector("FormSubmission")({
  file: {
    url: true,
  },
  form: {
    displayTitle: true,
  },
});

/**
 * It seems like this page is deprecated
 */
const DealJacketFormSubmissoinPage = () => {
  const { transactionId, formSubmissionId } = useParams(
    "/dashboard/transaction/:transactionId/dealJacket/formSubmission/:formSubmissionId"
  );
  const { data } = useQuery({
    queryKey: ["transaction-formSubmission", transactionId, formSubmissionId],
    refetchOnWindowFocus: false,
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId,
          },
          {
            id: true,
            buyer: {
              formSubmissions: [
                {
                  filter: {
                    id: {
                      equals: formSubmissionId,
                    },
                  },
                },
                { edges: { node: formSubmissionSelector } },
              ],
            },
            coBuyer: {
              formSubmissions: [
                {
                  filter: {
                    id: {
                      equals: formSubmissionId,
                    },
                  },
                },
                { edges: { node: formSubmissionSelector } },
              ],
            },
            title: true,
          },
        ],
      }),
  });
  const [numPages, setNumPages] = useState(0);
  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const transaction = data?.transaction;
  const formSubmission =
    transaction?.buyer?.formSubmissions.edges.at(0) ??
    transaction?.coBuyer?.formSubmissions.edges.at(0);

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-row justify-between">
        <BreadCrumbsContainer>
          <BreadCrumb title="Transaction">
            <Link to={"/dashboard"}>Transactions</Link>
          </BreadCrumb>

          <BreadCrumb title="User">
            <Link
              to={"/dashboard/transaction/:transactionId"}
              params={{
                transactionId,
              }}
            >
              {transaction?.title}
            </Link>
          </BreadCrumb>

          <BreadCrumb title="Deal Jacket">
            <Link
              to={"/dashboard/transaction/:transactionId/dealJacket"}
              params={{
                transactionId,
              }}
            >
              Deal Jacket
            </Link>
          </BreadCrumb>

          <BreadCrumb
            title={formSubmission?.node.form?.displayTitle ?? "Document"}
          />
        </BreadCrumbsContainer>
      </div>

      <div className="flex flex-1 overflow-scroll flex-col items-center">
        <div className="flex flex-col w-full items-center">
          {formSubmission?.node.file?.url ? (
            <Document
              file={formSubmission.node.file.url}
              onLoadSuccess={handleDocumentLoadSuccess}
              className="flex flex-col space-y-2"
              loading={
                <div className="flex w-full min-h-[80vh] justify-center items-center">
                  <Spinner />
                </div>
              }
              error={
                <div className="flex flex-col w-full min-h-[80vh] justify-center items-center">
                  <div className="flex flex-col space-y-4 items-center">
                    <div className="relative">
                      <FileErrorIcon className="w-20 text-dark-gray" />
                    </div>

                    <div className="text-subtitle text-dark-gray">
                      Failed to load document
                    </div>
                  </div>
                </div>
              }
            >
              {[...new Array(numPages)].map((_, index) => (
                <Page
                  key={index}
                  pageNumber={index + 1}
                  className="border border-very-light-gray"
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              ))}
            </Document>
          ) : (
            <div className="flex w-1/3 aspect-square items-center justify-center">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DealJacketFormSubmissoinPage;
