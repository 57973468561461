import { UserRole } from "@thedealersconcierge/lib/codecs/tdc";
import { CognitoUser } from "amazon-cognito-identity-js";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { z } from "zod";

// We store this is one entity to make it easier to manage lifecycle
type CognitoAuthData = {
  accessToken: string;
  idToken: string;
  refreshToken: string;
};

export const isCognitoAuth = (v: any): v is CognitoAuthData => {
  return Boolean(v?.accessToken);
};

export const cognitoUserAtom = atom<null | CognitoUser>(null);

type BackendAuthToken = {
  jwt: string;
};

export const authStateAtom = atomWithStorage<
  CognitoAuthData | BackendAuthToken | null
>("authStateAtom", null, undefined, {
  // Ensure that we have the auth state on page init
  getOnInit: true,
});

export const ActiveDealership = z.object({
  role: UserRole,
  dealershipId: z.string().uuid(),
  dealershipName: z.string(),
});

type ActiveDealership = z.TypeOf<typeof ActiveDealership>;

type DealershipData = {
  activeDealershipPerms: ActiveDealership;
};

export const dealershipAtom = atomWithStorage<DealershipData | null>(
  "dealershipAtom",
  null
);
