import store from "~/lib/store";
import { authStateAtom, cognitoUserAtom } from "~/state";
import { postAuthenticationAction } from "./postAuthenticationAction";

export const newPasswordAction = (
  newPassword: string
): Promise<"OK" | "MFA_REQUIRED"> => {
  return new Promise<"OK" | "MFA_REQUIRED">((resolve, reject) => {
    const user = store.instance.get(cognitoUserAtom);

    if (!user) {
      reject(new Error("Login first"));
      return;
    }

    user.completeNewPasswordChallenge(newPassword, [], {
      async onSuccess(resp) {
        store.instance.set(authStateAtom, {
          accessToken: resp.getAccessToken().getJwtToken(),
          idToken: resp.getIdToken().getJwtToken(),
          refreshToken: resp.getRefreshToken().getToken(),
        });
        await postAuthenticationAction();
        resolve("OK");
      },
      mfaRequired: () => resolve("MFA_REQUIRED"),
      onFailure(err) {
        reject(err);
      },
    });
  });
};
