import { TransactionStatusSchema } from "@thedealersconcierge/lib/codecs/tdc";
import { format } from "date-fns";
import { FC } from "react";
import Button from "~/components/Button";
import StatusBadge from "~/components/badges/StatusBadge";
import { getDateFromUnkown } from "~/lib/helpers";
import { ConsumerDatabaseQueryResultType } from "~/query/consumerDatabaseQuery";
import { useNavigate } from "~/router";

export type CustomerRoleInTransactionEnum =
  | "BUYER"
  | "CO_BUYER"
  | "DISSASOCIATED_BUYER"
  | "DISSASOCIATED_CO_BUYER";

const ConsumerDatabaseTransactionCard: FC<{
  customer: ConsumerDatabaseQueryResultType["userDealership"]["user"]["customers"]["edges"][0]["node"];
}> = ({ customer }) => {
  const userId = customer.userId;
  const transactionId = customer.transaction.id;

  const getUserRoleInHistory = (
    buyerId?: string,
    disassociatedRole?: string
  ): CustomerRoleInTransactionEnum => {
    if (disassociatedRole === "BUYER") return "DISSASOCIATED_BUYER";
    if (disassociatedRole === "CO_BUYER") return "DISSASOCIATED_CO_BUYER";
    if (buyerId == userId) return "BUYER";
    return "CO_BUYER";
  };

  const navigate = useNavigate();
  const handleGoToDisassociatedFiles = () => {
    navigate("/dashboard/customers/:userId/:transactionId/files", {
      params: { transactionId, userId },
    });
  };

  const customerRoleInTransaction = getUserRoleInHistory(
    customer.transaction.buyerId,
    customer.disassociatedRole
  );

  const transactionCreatedAt = customer.transaction.createdAt
    ? format(getDateFromUnkown(customer.transaction.createdAt), "dd-MM-yyyy")
    : "No Date";

  const handleGoToTransaction = () => {
    if (
      customerRoleInTransaction === "DISSASOCIATED_CO_BUYER" ||
      customerRoleInTransaction === "CO_BUYER"
    ) {
      navigate("/dashboard/transaction/:transactionId/co-buyer", {
        params: { transactionId },
      });
    } else {
      navigate("/dashboard/transaction/:transactionId", {
        params: { transactionId },
      });
    }
  };

  const isShowFileButton = () => {
    return customerRoleInTransaction === "DISSASOCIATED_CO_BUYER";
  };

  const getReadableRoleInTransaction = (
    role: CustomerRoleInTransactionEnum
  ) => {
    const readableRole = {
      BUYER: "Buyer",
      CO_BUYER: "Co-Buyer",
      DISSASOCIATED_BUYER: "Disassociated Buyer",
      DISSASOCIATED_CO_BUYER: "Disassociated Co-Buyer",
    };

    return readableRole[role];
  };

  return (
    <div className="rounded-2xl bg-white shadow-md space-y-8 flex flex-col lg:flex-row justify-between py-8 px-6 w-full">
      <div className="flex flex-row w-full justify-between">
        <div className="flex flex-row space-x-4 w-full">
          <p
            className="w-4/12 lg:w-2/12 cursor-pointer"
            onClick={handleGoToTransaction}
          >
            {transactionCreatedAt}
          </p>
          <p className="w-10/12 text-heading-3 lg:text-heading-2">
            {getReadableRoleInTransaction(customerRoleInTransaction)}
          </p>
        </div>

        <StatusBadge
          size="SMALL"
          status={TransactionStatusSchema.parse(customer.transaction.status)}
        />

        {isShowFileButton() && (
          <Button
            variant="TEXT_ONLY"
            onClick={handleGoToDisassociatedFiles}
            className="pt-0 pr-0"
          >
            File
          </Button>
        )}
      </div>
    </div>
  );
};

export default ConsumerDatabaseTransactionCard;
