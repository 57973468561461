import { Role } from "~/__generated__/backend/zeus";
import { gqlMutationClient } from "~/lib/backend";

const createUserGroupAction = async (
  userId: string,
  dealershipId: string,
  role: Role
) => {
  const resp = await gqlMutationClient()({
    createGroupuser: [
      {
        userId,
        dealershipId,
        role: role,
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationCreateGroupuserSuccess": {
          data: {
            status: true,
          },
        },
      },
    ],
  });

  if (resp.createGroupuser.__typename === "GraphQLError") {
    throw new Error(resp.createGroupuser.message);
  }

  return resp.createGroupuser.data;
};

export default createUserGroupAction;
