/**
 * This component generally needs to be rewritten to use tanstack table.
 */
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import classNames from "classnames";
import { format } from "date-fns";
import { useAtomValue } from "jotai";
import { PDFDocument } from "pdf-lib";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Button from "~/components/Button";
import { gqlQueryClient } from "~/lib/backend";
import { getReadableFormSubmissionType } from "~/lib/enumReadable";
import {
  DocumentLine,
  FormSubmissionGroup,
  handlePrintPrompt,
} from "~/pages/dashboard/transaction/[transactionId]/dealJacket/_dealJacketUtils";
import { customerFilesSelector } from "~/query/consumerDatabaseQuery";
import { Link, useNavigate, useParams } from "~/router";
import { dealershipAtom } from "~/state";

export default function DissacociatedFilesPage() {
  const dealership = useAtomValue(dealershipAtom);
  const navigate = useNavigate();
  const { userId, transactionId } = useParams(
    "/dashboard/customers/:userId/:transactionId/files"
  );

  const { data } = useQuery({
    queryKey: ["userData", userId, transactionId],
    queryFn: async () =>
      gqlQueryClient()(customerFilesSelector(userId, transactionId)),
  });

  const [selectedMask, setSelectedMask] = useState<{
    [selectedId: string]: boolean;
  }>({});

  const customer = data?.customer;
  const userFullName = `${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`;
  const formSubmissions = (customer?.formSubmissions.edges ?? []).map(
    (edge) => edge.node
  );

  // Form submissions
  const groupedFormSubmissions: FormSubmissionGroup[] = formSubmissions.reduce<
    FormSubmissionGroup[]
  >((accumulator, currentSubmission) => {
    const { type } = currentSubmission;
    const existingGroup = accumulator.find((group) => group.type === type);

    const cSubmissoin: DocumentLine = {
      id: currentSubmission.id,
      displayTitle:
        currentSubmission.form?.displayTitle ??
        getReadableFormSubmissionType(currentSubmission.type ?? "No title"),
      type: currentSubmission.type,
      createdAt: new Date(currentSubmission.createdAt),
      access: currentSubmission.access,
      name: `${currentSubmission.customer.firstName} ${currentSubmission.customer.lastName}`,
      isDocument: false,
      userId: currentSubmission.userId,
    };

    if (existingGroup) {
      existingGroup.formSubmissions.push(cSubmissoin);
    } else {
      accumulator.push({ type, formSubmissions: [cSubmissoin] });
    }

    return accumulator;
  }, []);

  // Add documents as a category
  const documents: DocumentLine[] =
    customer?.documents.edges.map((d) => {
      return {
        id: d.node.id,
        displayTitle: d.node.title,
        type: d.node.category,
        createdAt: new Date(d.node.createdAt),
        access: d.node.access,
        name: `${d.node.customer.firstName ?? ""} ${d.node.customer.lastName ?? ""}`.trim(),
        isDocument: true,
        file: d.node.file,
        userId: d.node.userId,
      };
    }) ?? [];
  if (documents.length > 0) {
    groupedFormSubmissions.push({
      type: "Documents",
      formSubmissions: documents,
    });
  }

  const handleOpenFormSubmission = (formSubmissionId: string) => {
    navigate(
      "/dashboard/customers/:userId/:transactionId/files/formSubmission/:formSubmissionId",
      {
        params: {
          transactionId,
          userId,
          formSubmissionId,
        },
      }
    );
  };

  const handleOpenDocument = (documentId: string) => {
    navigate(
      "/dashboard/customers/:userId/:transactionId/files/document/:documentId",
      {
        params: {
          transactionId,
          userId,
          documentId,
        },
      }
    );
  };

  const handlePrint = async () => {
    const selectedIds = Object.entries(selectedMask)
      .filter(([_key, selected]) => selected)
      .map(([id]) => id);

    // Populate list of urls from form submissions and additional documents
    const documentUrls: string[] = [];
    formSubmissions.forEach((fs) => {
      if (selectedIds.includes(fs.id) && fs.file?.url) {
        documentUrls.push(fs.file.url);
      }
    });
    documents.filter((doc) => {
      if (selectedIds.includes(doc.id) && doc.file?.url) {
        documentUrls.push(doc.file.url);
      }
    });

    const pdfDoc = await PDFDocument.create(); // create a new pdf document for bundling

    // Try catch block to handle errors in fetching and adding pages to the pdf
    // The fetch could fail and results in invalid pdfBuffer
    try {
      for (const url of documentUrls) {
        const pdfBuffer = await fetch(url).then((res) => res.arrayBuffer());
        const srcPdfDoc = await PDFDocument.load(pdfBuffer);
        const srcPageIndices = Array.from(
          { length: srcPdfDoc.getPageCount() },
          (_, i) => i
        );
        const copiedPages = await pdfDoc.copyPages(srcPdfDoc, srcPageIndices);
        copiedPages.forEach((page) => pdfDoc.addPage(page));
      }
    } catch (e) {
      Sentry.captureException(e);
      toast.error(
        "We could not print the document. Please try again or contact support."
      );
      return;
    }

    const combinedPdfBuffer = await pdfDoc.save();

    // Will open a print prompt on the same page
    // Create a blob and url for the pdf
    const blob = new Blob([combinedPdfBuffer], { type: "application/pdf" });
    handlePrintPrompt(blob);
  };

  const numberOfItems = formSubmissions.length + documents.length;

  const numChecked = Object.values(selectedMask).filter((mv) => !!mv).length;

  const toggleAll = () => {
    if (numberOfItems > numChecked) {
      // Check all
      const mask = groupedFormSubmissions
        .map((g) => g.formSubmissions)
        .flat()
        .reduce(
          (p, c) => ({
            ...p,
            [c.id]: true,
          }),
          {}
        );

      setSelectedMask(mask);
    } else {
      setSelectedMask({});
    }
  };

  const enablePrintAndSelect = dealership?.activeDealershipPerms
    ? permissionChecker("printDealerJacket", dealership?.activeDealershipPerms)
    : false;

  return (
    <div className="flex flex-col space-y-8 w-full">
      <div className="flex flex-row justify-between">
        <BreadCrumbsContainer>
          <BreadCrumb title="Customer Database">
            <Link to={"/dashboard/customers"}>Customer Database</Link>
          </BreadCrumb>

          <BreadCrumb title={userFullName}>
            <Link to={"/dashboard/customers/:userId"} params={{ userId }}>
              {userFullName}
            </Link>
          </BreadCrumb>

          <BreadCrumb title="Files" />
        </BreadCrumbsContainer>
      </div>

      <div className="w-full border border-dark-gray rounded-lg overflow-hidden">
        {/* Look into using: React table, https://tanstack.com/table/v8/docs/api/features/grouping */}
        <table className="w-full overflow-y-scroll text-sm">
          <thead className="relative">
            <tr className="relative bg-dark-gray text-white px-10 text-start">
              <td
                className="uppercase font-medium py-5 flex flex-row space-x-2 pl-10"
                width={196}
                onClick={enablePrintAndSelect ? toggleAll : undefined}
              >
                {enablePrintAndSelect ? (
                  <div
                    className={classNames({
                      "checkbox-unselected border-white":
                        numberOfItems > numChecked,
                      "checkbox-selected border-white":
                        numberOfItems === numChecked,
                    })}
                  />
                ) : null}

                <div>{numChecked} selected</div>
              </td>

              <td className="uppercase font-medium py-5">Document name</td>

              <td className="uppercase font-medium py-5">Date generated</td>

              <td className="uppercase font-medium py-5">Access</td>

              <td className="uppercase font-medium py-5">Uploaded by</td>

              <td className="items-center pr-10" width={64}>
                {enablePrintAndSelect && (
                  <Button size="SMALL" onClick={handlePrint}>
                    Print
                  </Button>
                )}
              </td>
            </tr>
          </thead>

          <tbody>
            {groupedFormSubmissions.map((group) => {
              return (
                <Fragment key={group.type}>
                  <tr className="bg-light-gray w-full">
                    <td colSpan={6} className="px-10 py-3 text-dark-gray">
                      {getReadableFormSubmissionType(group.type)}
                    </td>
                  </tr>

                  {group.formSubmissions.map((submission) => {
                    return (
                      <tr
                        key={submission.id}
                        className="w-full text-dark-gray cursor-pointer"
                        onClick={() =>
                          submission.isDocument
                            ? handleOpenDocument(submission.id)
                            : handleOpenFormSubmission(submission.id)
                        }
                      >
                        <td
                          className="pl-10  py-3"
                          width={196}
                          // We set the onClick listener a level out as I am
                          // always missing the button and clicking into the document and that is super annoying
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSelectedMask((mask) => ({
                              ...mask,
                              [submission.id]: !mask[submission.id],
                            }));
                          }}
                        >
                          {enablePrintAndSelect ? (
                            <div
                              className={classNames({
                                "checkbox-unselected":
                                  !selectedMask[submission.id],
                                "checkbox-selected":
                                  selectedMask[submission.id],
                              })}
                            />
                          ) : (
                            <div className="h-4"></div>
                          )}
                        </td>

                        <td className="max-w-xs">
                          <div className="line-clamp-1 text-very-dark-gray">
                            {submission.displayTitle ??
                              getReadableFormSubmissionType(submission.type)}
                          </div>
                        </td>

                        <td>
                          {format(submission.createdAt as Date, "dd/MM/yyyy")}
                        </td>

                        <td>
                          {submission.access === "BOTH"
                            ? "Dealership / Customer"
                            : submission.access === "DEALERSHIP"
                              ? "Dealership"
                              : ""}
                        </td>

                        {/**
                         * TODO: Make sure to use correct upload (currently not exposed)
                         */}
                        <td>{submission.name}</td>
                      </tr>
                    );
                  })}
                </Fragment>
              );
            })}

            {!groupedFormSubmissions.length && (
              <tr className="bg-light-gray w-full">
                <td colSpan={6} className="px-10 py-3 text-dark-gray">
                  <div className="text-dark-gray px-10 py-5">
                    No documents generated yet
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
