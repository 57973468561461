import { gqlMutationClient } from "~/lib/backend";

const suspendGroupUserAction = async (userId: string, isSuspended: boolean) => {
  const resp = await gqlMutationClient()({
    suspendGroupUser: [
      {
        userId,
        isSuspended,
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationSuspendGroupUserSuccess": {
          data: {
            status: true,
          },
        },
      },
    ],
  });

  if (resp.suspendGroupUser.__typename === "GraphQLError") {
    throw new Error(resp.suspendGroupUser.message);
  }

  return resp.suspendGroupUser.data;
};

export default suspendGroupUserAction;
